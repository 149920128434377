const Clear = props => {
  const { fill = '#717171', ...rest } = props
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle cx="8" cy="8" r="8" fill={fill} />
      <rect width="10.6667" height="10.6667" transform="translate(2.66675 2.66669)" fill={fill} />
      <path
        d="M10.8003 5.20476C10.627 5.03142 10.347 5.03142 10.1736 5.20476L8.00031 7.37365L5.82698 5.20031C5.65365 5.02698 5.37365 5.02698 5.20031 5.20031C5.02698 5.37365 5.02698 5.65365 5.20031 5.82698L7.37365 8.00031L5.20031 10.1736C5.02698 10.347 5.02698 10.627 5.20031 10.8003C5.37365 10.9736 5.65365 10.9736 5.82698 10.8003L8.00031 8.62698L10.1736 10.8003C10.347 10.9736 10.627 10.9736 10.8003 10.8003C10.9736 10.627 10.9736 10.347 10.8003 10.1736L8.62698 8.00031L10.8003 5.82698C10.9692 5.65809 10.9692 5.37365 10.8003 5.20476Z"
        fill="white"
      />
    </svg>
  )
}
export default Clear
