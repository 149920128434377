import { SvgIcon } from '@mui/material'

const VoiceInput = props => (
  <SvgIcon {...props} width="146" height="146" viewBox="0 0 146 146" fill="none">
    <rect
      opacity="0.5"
      x="23"
      y="23"
      width="100"
      height="100"
      rx="50"
      stroke="url(#paint0_linear_12827_131346)"
      strokeWidth="2"
    />
    <rect
      x="3"
      y="3"
      width="140"
      height="140"
      rx="70"
      fill="#E2FFF6"
      fillOpacity="0.2"
      stroke="url(#paint1_linear_12827_131346)"
      strokeOpacity="0.3"
      strokeWidth="6"
    />
    <rect
      x="36"
      y="36"
      width="74"
      height="74"
      rx="37"
      fill="white"
      stroke="#2A9A76"
      strokeWidth="2"
    />
    <rect x="68" y="59" width="10" height="17" rx="4.98701" fill="#2A9A76" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.8096 70.3333C64.8096 69.7021 64.298 69.1904 63.6668 69.1904C63.0356 69.1904 62.5239 69.7021 62.5239 70.3333V71.6666C62.5239 77.0662 66.6089 81.5116 71.8569 82.0812V86.8096C71.8569 87.4408 72.3686 87.9524 72.9998 87.9524C73.631 87.9524 74.1426 87.4408 74.1426 86.8096V82.0812C79.391 81.512 83.4763 77.0664 83.4763 71.6666V70.3333C83.4763 69.7021 82.9646 69.1904 82.3334 69.1904C81.7023 69.1904 81.1906 69.7021 81.1906 70.3333V71.6666C81.1906 76.1901 77.5236 79.8571 73.0001 79.8571C68.4766 79.8571 64.8096 76.1901 64.8096 71.6666V70.3333Z"
      fill="#2A9A76"
    />
    <defs>
      <linearGradient
        id="paint0_linear_12827_131346"
        x1="73"
        y1="23"
        x2="73"
        y2="123"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#33C998" stopOpacity="0" />
        <stop offset="0.25" stopColor="#3ABD93" />
        <stop offset="0.75" stopColor="#35C295" />
        <stop offset="1" stopColor="#36C999" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_12827_131346"
        x1="73"
        y1="3"
        x2="73"
        y2="143"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#48D9AA" stopOpacity="0" />
        <stop offset="0.25" stopColor="#44DAA9" stopOpacity="0.62" />
        <stop offset="0.75" stopColor="#3FDCA9" stopOpacity="0.64" />
        <stop offset="1" stopColor="#35C194" stopOpacity="0" />
      </linearGradient>
    </defs>
  </SvgIcon>
)
export default VoiceInput
