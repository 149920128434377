import { useEffect, useRef, useState } from 'react'

const useScrollOutMonitor = () => {
  const targetRef = useRef(null)
  const containerRef = useRef(null)
  const [arrived, setArrived] = useState(false)

  useEffect(() => {
    if (!containerRef?.current || !targetRef?.current) return () => {}
    containerRef.current?.addEventListener('scroll', () => {
      // 获取滚动高度
      const { scrollTop } = containerRef.current || {}
      const { offsetHeight } = targetRef?.current || {}

      setArrived(scrollTop > offsetHeight)
    })

    return () => {
      containerRef.current?.removeEventListener('scroll', () => {})
    }
  }, [containerRef.current, targetRef?.current])

  return { containerRef, targetRef, arrived }
}

export default useScrollOutMonitor
