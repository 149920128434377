import { FC, useMemo, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  SxProps,
  Theme,
  Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { statsigLog } from '@/lib/statsigAndGa'
import { FAQ_ITEM_TYPE } from './faqData'

interface FaqContentProps {
  from?: string
  className?: string
  sx?: SxProps<Theme>
  selectFaqKey: number | null
  defaultExpandKeys?: number[]
  faqs: FAQ_ITEM_TYPE[]
}
const FaqContent: FC<FaqContentProps> = props => {
  const {
    selectFaqKey,
    faqs,
    defaultExpandKeys = [],
    className = '',
    sx = {},
    from = 'faq'
  } = props
  const showFaqs = useMemo(() => {
    if (selectFaqKey === null) return faqs
    return faqs.filter(faq => faq.key === selectFaqKey)
  }, [selectFaqKey, faqs])
  const [expands, setExpands] = useState<number[]>(defaultExpandKeys)

  const onChangeExpand = (key: number, question: string) => (_, currentExpanded) => {
    const keys = [...expands]
    if (currentExpanded) {
      keys.push(key)
    } else {
      keys.splice(keys.indexOf(key), 1)
    }
    setExpands(keys)
    if (from === 'home') {
      statsigLog('homepage_faq_content_click', {
        faqDetail: question
      })
    }
  }
  return (
    <Box className={`px-5 pt-10 md:px-36 md:pt-15 ${className}`} sx={sx}>
      {showFaqs.map((faq, index) => (
        <Box key={faq.key}>
          <Accordion
            sx={{
              '&:before': {
                display: 'none'
              }
            }}
            defaultExpanded
            expanded={expands.includes(faq.key)}
            onChange={onChangeExpand(faq.key, faq.question)}
            className="shadow-none border-gray-400"
          >
            <AccordionSummary
              sx={{
                padding: 0,
                '&.MuiAccordionSummary-root': {
                  minHeight: 'unset'
                },
                '&.MuiAccordionSummary-root .Mui-expanded': {
                  margin: '12px 0'
                }
              }}
              expandIcon={<ExpandMoreIcon className="text-tertiary" />}
              aria-controls={`panel${index}-content`}
            >
              <Typography className="font-averta font-bold text-lg">{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                padding: 0,
                paddingBottom: '12px'
              }}
            >
              <Typography className="text-secondary font-averta text-base">{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
          {index !== showFaqs.length - 1 && <Divider className="my-3 border-gray-200" />}
        </Box>
      ))}
    </Box>
  )
}
export default FaqContent
