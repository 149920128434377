const LocationLinear = props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.99862 1.6665C6.77362 1.6665 4.16528 4.27484 4.16528 7.49984C4.16528 10.9748 7.84862 15.7665 9.36528 17.5915C9.69862 17.9915 10.307 17.9915 10.6403 17.5915C12.1486 15.7665 15.832 10.9748 15.832 7.49984C15.832 4.27484 13.2236 1.6665 9.99862 1.6665ZM9.99862 9.58317C8.84862 9.58317 7.91528 8.64984 7.91528 7.49984C7.91528 6.34984 8.84862 5.4165 9.99862 5.4165C11.1486 5.4165 12.082 6.34984 12.082 7.49984C12.082 8.64984 11.1486 9.58317 9.99862 9.58317Z"
      stroke="url(#paint0_linear_13911_51739)"
      strokeWidth="1.6"
    />
    <defs>
      <linearGradient
        id="paint0_linear_13911_51739"
        x1="9.99862"
        y1="1.6665"
        x2="9.99862"
        y2="17.8915"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#8B8B8B" />
      </linearGradient>
    </defs>
  </svg>
)
export default LocationLinear
