import { gql } from '@apollo/client'

const searchRecordGQL = gql`
  mutation CreateSearchRecord($input: SearchRecordInput!) {
    createSearchRecord(input: $input) {
      succeed
    }
  }
`

export default searchRecordGQL
