const CityOrArea = props => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Buildings" clipPath="url(#clip0_12731_113713)">
      <path
        id="Vector"
        d="M16.0877 16.0876H1.91251C1.70365 16.0876 1.50334 16.0046 1.35566 15.857C1.20797 15.7093 1.125 15.509 1.125 15.3001V9.38354C1.12499 9.27102 1.14909 9.1598 1.19568 9.05737C1.24228 8.95494 1.31028 8.86369 1.39512 8.78976L4.27504 6.2784V2.69995C4.27504 2.49109 4.35801 2.29079 4.50569 2.1431C4.65338 1.99541 4.85368 1.91245 5.06255 1.91245H16.0877C16.2965 1.91245 16.4968 1.99541 16.6445 2.1431C16.7922 2.29079 16.8752 2.49109 16.8752 2.69995V15.3001C16.8752 15.509 16.7922 15.7093 16.6445 15.857C16.4968 16.0046 16.2965 16.0876 16.0877 16.0876ZM6.63756 14.5126H9.00009V9.74186L5.85005 6.99503L2.70002 9.74186V14.5126H5.06255V11.3626H6.63756V14.5126ZM10.5751 14.5126H15.3002V3.48746H5.85005V5.16249C6.03433 5.16249 6.2194 5.22707 6.36745 5.35701L10.305 8.78976C10.3898 8.86369 10.4578 8.95494 10.5044 9.05737C10.551 9.1598 10.5751 9.27102 10.5751 9.38354V14.5126ZM12.1501 8.21252H13.7251V9.78754H12.1501V8.21252ZM12.1501 11.3626H13.7251V12.9376H12.1501V11.3626ZM12.1501 5.06248H13.7251V6.6375H12.1501V5.06248ZM9.00009 5.06248H10.5751V6.6375H9.00009V5.06248Z"
        fill="#000000"
      />
    </g>
    <defs>
      <clipPath id="clip0_12731_113713">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
export default CityOrArea
