import { Box, Button } from '@mui/material'
import 'swiper/css/navigation'
import 'swiper/css'
import { useIntl } from 'react-intl'
import { generatePath } from '@/lib/urlParse'
import { useRouter } from 'next/router'
import { statsigLog } from '@/lib/statsigAndGa'

const BrandDesc = () => {
  const intl = useIntl()
  const router = useRouter()

  return (
    <>
      <style jsx global>
        {`
          .brand-description-bg-img {
            background-image: url(${process.env.CDN_PREFIX}/web/f64f6f734ab4f3c2.jpg);
            background-position: center;
            background-size: cover;
          }
          .brand-description-bg-img-mobile {
            background-image: url(${process.env.CDN_PREFIX}/web/c057c9d9392da407.jpg);
            background-size: cover;
            background-origin: center;
          }
        `}
      </style>
      <Box className="bg-white">
        <Box className="bg-gray-100 w-full flex flex-col justify-center items-center py-6 md:py-[60px] px-5 my-[60px]">
          <Box className="h-full lg:h-fit w-full max-w-[1160px] flex flex-col md:items-center gap-10">
            <Box className="text-primary text-[22px] md:text-5xl font-semibold">
              {intl.formatMessage({ id: 'home.brand.intro.title1' })}
            </Box>
            <Box className="text-[20px]">
              {intl.formatMessage({ id: 'home.brand.intro.content' })}
            </Box>
          </Box>
        </Box>
        <Box
          className="relative w-full h-[200px] md:h-[320px] brand-description-bg-img md:brand-description-bg-img-mobile flex flex-col justify-center items-center gap-5 md:gap-6"
          sx={{
            '::before': {
              content: "''",
              display: 'block',
              width: '100%',
              height: '100%',
              position: 'absolute',
              zIndex: '0',
              background: 'linear-gradient(180deg, rgba(5, 10, 34, 0.00) 50%, #050A22 100%)'
            }
          }}
        >
          <Box className="font-ramaraja text-white text-[32px] md:text-[64px] text-center leading-8  md:leading-[60px]">
            {intl.formatMessage({ id: 'home.brand.intro.title2' })}
          </Box>
          <Box
            className="p-[3px] rounded-xl z-10"
            sx={{
              background: 'rgba(176, 198, 170, 0.40)'
            }}
          >
            <Button
              className="w-fit text-white py-3 px-7 md:px-8 md:py-4 !rounded-xl normal-case font-semibold text-base !bg-[#00A972]"
              onClick={() => {
                statsigLog('homepage_brand_introduce_button_click')
                router.push(generatePath())
              }}
            >
              {intl.formatMessage({ id: 'home.brand.intro.button' })}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default BrandDesc
