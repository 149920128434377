import { makeVar } from '@apollo/client'
import { AlertProps } from '@mui/material'

interface IShowAlert {
  state: boolean
  msg?: string
  /**
   * AlertProps['severity']: 'success' | 'info' | 'warning' | 'error';
   */
  type?: AlertProps['severity']
}
export const showAlert = makeVar({
  state: false
} as IShowAlert)
