import { debounce } from 'lodash'
import locateCity from '@/graphqls/locateCity.gql'
import { useLazyQuery } from '@apollo/client'
import { showAlert } from '@/state/alert'
import { statsigLog } from '@/lib/statsigAndGa'
import { getGeoLoading } from '@/state/loading'
/**
 */
interface GeolocationPositionError {
  readonly code: number
  readonly message: string
}
enum ERR_REASON {
  PERMISSION_DENIED = 1,
  POSITION_UNAVAILABLE = 2,
  TIMEOUT = 3
}
const ERR_REASON_TEXT = {
  PERMISSION_DENIED:
    'Enable location services to find homes nearby. [Allow location access] for assistance.',
  POSITION_UNAVAILABLE:
    "We're unable to pinpoint your location. Please check your device's location settings and try again.",
  TIMEOUT:
    'Location request timed out. Please refresh the page or enter your location manually to continue.'
}

const useGetGeolocation = ({ successCallback }) => {
  const [getLocationCity] = useLazyQuery(locateCity, {
    fetchPolicy: 'network-only'
  })
  const syncGetLocationCity = async (latitude, longitude) => {
    try {
      const res = await getLocationCity({
        variables: {
          lat: String(latitude),
          lon: String(longitude)
        }
      })
      const { locateCity: city } = res?.data || {}
      if (city) {
        statsigLog('get_city_by_geo', { latitude, longitude, city })
        getGeoLoading(false)
        successCallback({ city, latitude, longitude })
      } else {
        getGeoLoading(false)
        showAlert({ state: true, msg: ERR_REASON_TEXT[ERR_REASON[2]], type: 'info' })
      }
    } catch (error) {
      getGeoLoading(false)
    }
  }

  const handleGetGeolocation = debounce(() => {
    getGeoLoading(true)
    statsigLog('search_by_location_click')
    navigator?.geolocation?.getCurrentPosition(
      position => {
        const { latitude = '', longitude = '' } = position?.coords || {}
        statsigLog('allow_search_by_location_click', { latitude, longitude })
        syncGetLocationCity(latitude, longitude)
      },
      ({ code }: GeolocationPositionError) => {
        getGeoLoading(false)
        showAlert({ state: true, msg: ERR_REASON_TEXT[ERR_REASON[code]], type: 'info' })
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      }
    )
  }, 1000)

  return { handleGetGeolocation }
}

export default useGetGeolocation
