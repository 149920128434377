import { Box } from '@mui/material'
import { useIntl } from 'react-intl'
import TabsList from './tabsList'

const MultipleServices = () => {
  const intl = useIntl()
  return (
    <Box className="bg-white md:bg-gray-100 py-[30px] md:py-[60px] px-5">
      <Box className="flex justify-center">
        <Box className="h-fit w-full max-w-[1160px] flex flex-col items-center gap-6 md:gap-10">
          <Box className="text-primary text-[22px] leading-8 md:text-5xl font-semibold">
            {intl.formatMessage({ id: 'home.multiple.services.title' })}
          </Box>
          <Box className="w-full">
            <TabsList />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default MultipleServices
