import { SvgIcon } from '@mui/material'

export default function bath(props) {
  return (
    <SvgIcon {...props} fill="none" viewBox="0 0 24 24" width="24" height="24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3358 3C16.5256 3 15.8688 3.65679 15.8688 4.46698V5.16249L14.0543 4.04306C12.7246 3.22276 11.0459 3.22276 9.71622 4.04306L3.96212 7.59285C2.74257 8.34521 2 9.67582 2 11.1088V19.0441C2 20.1243 2.87572 21 3.95597 21H19.8145C20.8948 21 21.7705 20.1243 21.7705 19.0441V11.1088C21.7705 9.67582 21.0279 8.34521 19.8084 7.59285L19.4098 7.34698V4.46698C19.4098 3.65679 18.753 3 17.9428 3H17.3358Z"
        fill="#024239"
      />
      <path
        d="M15.8688 5.16249L15.5375 5.69949L16.4998 6.29313V5.16249H15.8688ZM14.0543 4.04306L14.3856 3.50605L14.0543 4.04306ZM9.71622 4.04306L10.0475 4.58006L9.71622 4.04306ZM3.96212 7.59285L4.29341 8.12986L3.96212 7.59285ZM19.8084 7.59285L19.4771 8.12985L19.8084 7.59285ZM19.4098 7.34698H18.7788V7.6991L19.0785 7.88398L19.4098 7.34698ZM16.4998 4.46698C16.4998 4.00527 16.8741 3.63097 17.3358 3.63097V2.36903C16.1771 2.36903 15.2379 3.30831 15.2379 4.46698H16.4998ZM16.4998 5.16249V4.46698H15.2379V5.16249H16.4998ZM13.723 4.58006L15.5375 5.69949L16.2001 4.62548L14.3856 3.50605L13.723 4.58006ZM10.0475 4.58006C11.1741 3.88505 12.5964 3.88505 13.723 4.58006L14.3856 3.50605C12.8528 2.56046 10.9177 2.56046 9.38493 3.50605L10.0475 4.58006ZM4.29341 8.12986L10.0475 4.58006L9.38493 3.50605L3.63083 7.05584L4.29341 8.12986ZM2.63097 11.1088C2.63097 9.89468 3.26012 8.7673 4.29341 8.12986L3.63083 7.05584C2.22501 7.92312 1.36903 9.45695 1.36903 11.1088H2.63097ZM2.63097 19.0441V11.1088H1.36903V19.0441H2.63097ZM3.95597 20.3691C3.22419 20.3691 2.63097 19.7758 2.63097 19.0441H1.36903C1.36903 20.4728 2.52724 21.631 3.95597 21.631V20.3691ZM19.8145 20.3691H3.95597V21.631H19.8145V20.3691ZM21.1395 19.0441C21.1395 19.7758 20.5463 20.3691 19.8145 20.3691V21.631C21.2432 21.631 22.4015 20.4728 22.4015 19.0441H21.1395ZM21.1395 11.1088V19.0441H22.4015V11.1088H21.1395ZM19.4771 8.12985C20.5104 8.7673 21.1395 9.89468 21.1395 11.1088H22.4015C22.4015 9.45695 21.5455 7.92312 20.1397 7.05584L19.4771 8.12985ZM19.0785 7.88398L19.4771 8.12985L20.1397 7.05584L19.7411 6.80997L19.0785 7.88398ZM18.7788 4.46698V7.34698H20.0408V4.46698H18.7788ZM17.9428 3.63097C18.4046 3.63097 18.7788 4.00527 18.7788 4.46698H20.0408C20.0408 3.30831 19.1015 2.36903 17.9428 2.36903V3.63097ZM17.3358 3.63097H17.9428V2.36903H17.3358V3.63097Z"
        fill="url(#paint0_linear_3205_43478)"
      />
      <path
        d="M7.18573 12.0884C7.27246 11.602 7.7026 11.2623 8.19669 11.2623C8.69077 11.2623 9.12091 11.602 9.20764 12.0884C9.2922 12.5626 9.37701 13.1549 9.37701 13.623C9.37701 14.0911 9.2922 14.6834 9.20764 15.1576C9.12091 15.644 8.69077 15.9836 8.19669 15.9836C7.7026 15.9836 7.27246 15.644 7.18573 15.1576C7.10117 14.6834 7.01636 14.0911 7.01636 13.623C7.01636 13.1549 7.10117 12.5626 7.18573 12.0884Z"
        fill="#C6F3FF"
      />
      <path
        d="M16.0164 15.541L14.9964 14.2353C14.7328 13.8979 14.7484 13.4201 15.0336 13.1007L16.0164 12"
        stroke="#C6F3FF"
        strokeWidth="1.26195"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3205_43478"
          x1="2.83494"
          y1="10.934"
          x2="22.0385"
          y2="10.934"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#75E6A0" />
          <stop offset="1" stopColor="#6F97FF" />
        </linearGradient>
      </defs>
    </SvgIcon>
  )
}
