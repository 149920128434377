import { FC } from 'react'
import { Box } from '@mui/material'
import { useIntl } from 'react-intl'
import { RentyAIProperty } from '@/interface/property'
import GuessULike from '../guessULike'

interface IRecommendListProps {
  guessULikeList: RentyAIProperty[]
}

const RecommendList: FC<IRecommendListProps> = ({ guessULikeList }) => {
  const intl = useIntl()
  return (
    <Box className="max-w-[1160px] mx-auto bg-white text-primary w-full py-8 mb-[60px]">
      <Box
        component="h2"
        className="px-5 md:px-2 text-[22px] md:text-[28px] lg:leading-[42px] font-semibold mb-4 md:mb-6"
      >
        {intl.formatMessage({ id: 'home.recommended.homes.title' })}
      </Box>
      <GuessULike list={guessULikeList} eventName="new_home_propertyRec_click" />
    </Box>
  )
}

export default RecommendList
