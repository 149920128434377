const Search = props => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.80833 12.8373C10.8635 12.8373 13.3403 10.3606 13.3403 7.30534C13.3403 4.25012 10.8635 1.77338 7.80833 1.77338C4.75311 1.77338 2.27637 4.25012 2.27637 7.30534C2.27637 10.3606 4.75311 12.8373 7.80833 12.8373Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.7231 14.2184L11.7151 11.2104"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default Search
