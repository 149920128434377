import { generatePath, generateZipcodePath, getDetailPageUrlPath } from '@/lib/urlParse'
import { encodeFilterPath } from '@/lib/filterCode'
import { SuggestionsDataProps, SUGGESTION_TYPE_ENUM } from './homeSearch/input'

export const gotoPage = (suggest: SuggestionsDataProps | string) => {
  if (typeof suggest === 'string') {
    window.location.href = `${generatePath()}`
    return
  }
  const { suggestionType, ext } = suggest as SuggestionsDataProps
  if ([SUGGESTION_TYPE_ENUM.ADDRESS, SUGGESTION_TYPE_ENUM.PROPERTY_NAME].includes(suggestionType)) {
    // setSuggestionInStorage({ type: 'text', value: ext.propertyName as string })
    // window.location.href = `${generatePath()}`
    const url = `${getDetailPageUrlPath(ext.city, '', ext.propertyName, ext.propertyID)}`
    window.location.href = url
    return
  }
  if ([SUGGESTION_TYPE_ENUM.AREA_NAME, SUGGESTION_TYPE_ENUM.CITY_NAME].includes(suggestionType)) {
    window.location.href = `${generatePath(ext.city, ext.area || '')}/${encodeFilterPath(
      ext.filter
    )}`
    return
  }
  if (SUGGESTION_TYPE_ENUM.ZIP_CODE === suggestionType) {
    window.location.href = generateZipcodePath(ext.zipCode)
    return
  }
  window.location.href = generatePath()
}
