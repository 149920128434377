export const replaceHighlight = (input: string) => {
  const replaced = input
    .replace(/<em>/g, "<span style='font-weight: 700'>")
    .replace(/<\/em>/g, '</span>')
  return replaced
}

export const getVID = () => localStorage.getItem('VID') || ''

export const transIdToInt = obj => {
  let newObj = { ...obj }
  if (newObj.propertyID) {
    newObj = { ...newObj, propertyID: ~~newObj.propertyID }
  } else if (newObj.ext?.propertyID) {
    newObj = { ...newObj, ext: { ...newObj.ext, propertyID: ~~newObj.ext.propertyID } }
  }
  return newObj
}
