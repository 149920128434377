import { Box } from '@mui/material'
import AiAvatar from '../../icons/aiAvatarMobile'
import VoiceIcon from '../../icons/newHome/voice1'
import VoiceInput from '../../icons/newHome/voice2'

const LeftItem = ({ content, className = '' }: { content: string; className?: string }) => (
  <Box className={`w-fit max-w-full flex justify-start mt-4 ${className}`}>
    <AiAvatar className="text-[27px] mr-3" />
    <Box
      className="text-black text-xs md:text-base font-normal py-1 px-2 rounded-lg border"
      sx={{
        backgroundImage: 'linear-gradient(180deg, #F9FDF4 0%, #EEFBFB 100%)',
        boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.05)',
        borderColor: 'rgba(211, 221, 204, 0.60)'
      }}
    >
      {content}
    </Box>
  </Box>
)
const RightItem = ({ content }: { content: string }) => (
  <Box className="w-full flex justify-end mt-4">
    <Box
      className="text-black text-xs md:text-base font-normal w-w-fit py-1 px-2 rounded-lg border border-[#E9E9E9] bg-white"
      sx={{
        boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.10)'
      }}
    >
      {content}
    </Box>
  </Box>
)
const RightVoiceItem = ({ content }: { content: string }) => (
  <Box className="w-full flex justify-end mt-4 relative">
    <Box
      className="text-black text-lg md:text-[20px] font-normal w-w-fit py-2 md:py-[12px] pl-3 pr-[60px] rounded-xl border border-[#E9E9E9] bg-white flex justify-center items-center gap-[6px] mb-[90px] md:mb-0"
      sx={{
        boxShadow: '0px 0px 6.1px 0px rgba(0, 0, 0, 0.10)'
      }}
    >
      <VoiceIcon className="text-lg md:text-2xl" />
      {content}
    </Box>
    <VoiceInput className="text-[140px] absolute top-[70%] left-[50%] md:left-[102%] -translate-x-[50%] -translate-y-[50%] md:translate-x-[0] md:-translate-y-[50%] -z-[1]" />
  </Box>
)
const MessageItem = { RightVoiceItem, RightItem, LeftItem }
export default MessageItem
