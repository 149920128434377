const Geolocation = props => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.89122 16.5639L7.33363 10.9594C7.28608 10.7883 7.16051 10.6497 6.99492 10.5855L1.27219 8.36797C0.804076 8.18658 0.790034 7.52936 1.24997 7.32814L16.605 0.610324C17.0836 0.400904 17.5643 0.900351 17.3368 1.37067L9.93952 16.6583C9.71364 17.1251 9.03008 17.0636 8.89122 16.5639Z"
      fill="#717171"
    />
  </svg>
)
export default Geolocation
