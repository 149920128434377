/* eslint-disable no-nested-ternary */
import { findCaFromZipCode } from '@/lib/zipCode'
import moment from 'moment'
import kebabCase from 'lodash/kebabCase'
import invert from 'lodash/invert'

// 学校附近的房子，城市区域只能是这些
export const CAMPUS_CITY = ['Los Angeles']
export const CAMPUS_AREA = ['USC']

export const amenitiesRouteMap = {
  'pet-friendly': 'Pet Friendly',
  'with-parking': 'Parking',
  'with-private-bathroom': 'Private Bathroom',
  furnished: 'Furnished',
  water: 'Water',
  tv: 'TV',
  sauna: 'Sauna',
  'in-unit-wd': 'In-Unit W/D',
  'car-charging-station': 'Car Charging Station',
  'security-camera': 'Security Camera',
  'irrigation-system': 'Irrigation System',
  'study-lounge': 'Study Lounge',
  sofa: 'Sofa',
  'roof-terrace': 'Roof Terrace',
  desk: 'Desk',
  'private-wd': 'Private W/D',
  pool: 'Pool',
  shower: 'Shower',
  wifi: 'Wifi',
  'trash-chutes': 'Trash Chutes',
  balcony: 'Balcony',
  dishwasher: 'Dishwasher',
  'community-kitchen': 'Community Kitchen',
  'on-site-laundry': 'On-site Laundry',
  heater: 'Heater',
  'fitness-center': 'Fitness Center',
  refrigerator: 'Refrigerator',
  'controlled-access': 'Controlled Access',
  'movie-room': 'Movie Room',
  'renter-insurance': 'Renter’s Insurance',
  gas: 'Gas',
  'application-policy': 'Application Policy',
  internet: 'Internet',
  jacuzzi: 'Jacuzzi',
  'package-locker': 'Package Locker',
  'dog-park': 'Dog Park',
  'garbage-disposal': 'Garbage Disposal',
  chair: 'Chair',
  dps: 'DPS',
  'smart-lock': 'Smart Lock',
  'pet-washing-station': 'Pet Washing Station',
  microwave: 'Microwave',
  'move-in-charges': 'Move-in Charges',
  'on-Site-wd': 'On-Site W/D',
  'valet-trash-service': 'Valet Trash Service',
  monitor: 'Monitor',
  electricity: 'Electricity',
  oven: 'Oven',
  'walk-to-campus': 'Walk To Campus',
  'grill-station': 'Grill Station',
  elevator: 'Elevator',
  'murphy-bed': 'Murphy Bed',
  'occupancy-policy': 'Occupancy Policy',
  'public-transportation': 'Public Transportation',
  'shuttle-to-campus': 'Shuttle to Campus',
  wardrobe: 'Wardrobe',
  'free-lyft': 'Free Lyft',
  'game-room': 'Game Room',
  garden: 'Garden',
  'island-table': 'Island Table',
  'bike-storage': 'Bike Storage',
  'security-guard': 'Security Guard',
  cabana: 'Cabana',
  sewer: 'Sewer',
  yard: 'Yard',
  'storage-space': 'Storage Space',
  stove: 'Stove',
  'yoga-room': 'Yoga Room',
  'conference-room': 'Conference Room',
  'club-house': 'Club house',
  bathtub: 'Bathtub',
  'induction-cooking': 'Induction Cooking',
  'ac-port': 'AC port',
  'shoe-cabinet': 'Shoe Cabinet'
}

export const encodeFilterPath = (filter = {}) => {
  if (!filter) {
    return ''
  }
  const keys = [
    'bedsFilterBy',
    'minPrice',
    'maxPrice',
    'moveInStartTimeEqualTo',
    'moveInEndTimeEqualTo',
    'amenities',
    'isLuxuryApartments'
  ]
  const filterKeys = Object.keys(filter)
  const filterPathList = keys
    .filter(k => filterKeys.includes(k))
    .filter(k => ![null, undefined].includes(filter[k]))
    .map(k => {
      if (k === 'bedsFilterBy') {
        const [num] = filter[k]
        return num === 0 ? 'studio' : `${num}-bedroom`
      }
      if (['minPrice', 'maxPrice'].includes(k) && ~~filter[k] > 1) {
        return `${kebabCase(k)}-${filter[k]}`
      }
      if (['moveInStartTimeEqualTo', 'moveInEndTimeEqualTo'].includes(k)) {
        const key = k === 'moveInStartTimeEqualTo' ? 'move-in-start' : 'move-in-end'
        return `${key}-${filter[k]}`
      }
      if (k === 'amenities') {
        const mapData = invert(amenitiesRouteMap)
        const routeList = filter[k].map(v => mapData[v]).filter(v => v)
        return routeList.join('-')
      }
      if (k === 'isLuxuryApartments' && filter[k]) {
        return 'luxury'
      }
      return ''
    })
    .filter(v => v)
  return filterPathList.join('-')
}

/**
 * @description 解析url的筛选条件
 * @param path - 路由，比如 1-bedroom-width-parking-max-price-2000
 * 偏好最好遵从 [价格]-[户型]-[时间]-[amenities] 顺序来构建path
 */
export const decodeFilterPath = (path: string) => {
  let curPath = path
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const filters: any = {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let isCheapSort = false

  const regMap = [
    { reg: /min-price-(\d+)-?/, key: 'minPrice' },
    { reg: /max-price-(\d+)-?/, key: 'maxPrice' },
    { reg: /move-in-start-(\d+)-?/, key: 'moveInStartTimeEqualTo' },
    { reg: /move-in-end-(\d+)-?/, key: 'moveInEndTimeEqualTo' },
    { reg: /(([1-6]-bedroom)|studio)-?/, key: 'bedsFilterBy' },
    { reg: /zip-code-(\d+)/, key: 'zip' },
    {
      reg: new RegExp(`(${Object.keys(amenitiesRouteMap).join('|')})-?`, 'g'),
      key: 'amenities'
    }
  ]

  // 只有LA 的 down town 才匹配
  if (curPath.match(/(luxury)-?/)) {
    filters.isLuxuryApartments = true
  }

  if (curPath.match(/(cheap-apartments)-?/)) {
    curPath = curPath.replace(/(cheap-apartments)-?/, '')
    isCheapSort = true
  }

  if (curPath.match(/(available-now)-?/)) {
    curPath = curPath.replace(/(cheap-apartments)-?/, '')
    filters.moveInStartTimeEqualTo = Date.now()
    filters.moveInEndTimeEqualTo = Date.now()
  }

  if (curPath.match(/(small-apartments)-?/)) {
    curPath = curPath.replace(/(cheap-apartments)-?/, '')
    filters.bedsFilterBy = [0, 1]
  }

  for (let i = 0; i < regMap.length; i += 1) {
    const matchRes = curPath.match(regMap[i].reg)
    const [, value] = matchRes || []
    if (!matchRes) {
      // eslint-disable-next-line no-continue
      continue
    }

    switch (i) {
      case 0:
      case 1:
      case 2:
      case 3:
        {
          const { key } = regMap[i]
          filters[key] = ~~value
        }
        break
      case 4:
        if (value === 'studio') {
          filters.bedsFilterBy = [0]
        } else {
          let num = ~~value[0]
          const nums = []
          while (num <= 6) {
            nums.push(num)
            num += 1
          }
          filters.bedsFilterBy = nums
        }
        break
      case 5:
        {
          const { city, area } = findCaFromZipCode(value)
          filters.cityEqualTo = city
          filters.areaEqualTo = area
        }
        break
      case 6:
        filters.amenities = matchRes
          .map(v => (v.endsWith('-') ? v.substring(0, v.length - 1) : v))
          .map(v => amenitiesRouteMap[v])
          .filter(v => v)
        break
      default:
        break
    }
    curPath = curPath.replace(regMap[i].reg, '')
  }

  if (filters.maxPrice && !filters.minPrice) {
    filters.minPrice = 1
  }

  return {
    filters,
    isCheapSort
  }
}

export const transformFilter2Message = filter => {
  const filterWordsMap = {
    minPrice: val => (~~val > 1 ? `over $${val}` : ''),
    maxPrice: val => (~~val > 1 ? `under $${val}` : ''),
    bedsFilterBy: val =>
      ![undefined, null].includes(val) ? (~~val === 0 ? 'studio' : `over ${val} bedroom`) : '',
    moveInStartTimeEqualTo: val =>
      ![undefined, null].includes(val) ? `move in ${moment(+val).format('YYYY/MM/DD')}` : '',
    moveInEndTimeEqualTo: val =>
      ![undefined, null].includes(val) ? `move out ${moment(+val).format('YYYY/MM/DD')}` : '',
    amenities: (val = []) => (val ? val.join(', ') : '')
  }
  const filterMapKeys = Object.keys(filterWordsMap)
  const filterKeys = Object.keys(filter || {})
  const filterWordList = filterMapKeys
    .filter(k => filterKeys.includes(k))
    .map(k => filterWordsMap[k](filter[k]))
    .filter(v => v)
  return filterWordList.length > 2
    ? filterWordList.slice(0, filterWordList.length - 1).join(',') +
        filterWordList[filterWordList.length - 1]
    : filterWordList.join(', ')
}
