import { gql } from '@apollo/client'
import { searchSuggestionFragment } from './fragments/searchSuggestion'

const searchSuggestionsGQL = gql`
  ${searchSuggestionFragment}
  query SearchSuggestions($input: String) {
    searchSuggestions(input: $input) {
      ...searchSuggestion
    }
  }
`
export default searchSuggestionsGQL
