import { Box, useMediaQuery } from '@mui/material'
import LazyImg from '@/components/img/lazyImg'
import { memo, useEffect, useState } from 'react'
import MessageItem from './messageItem'

const { LeftItem, RightItem } = MessageItem
const bgImg = `${process.env.CDN_PREFIX}/web/6defcb020b517643.png`
const mobileBgImg = `${process.env.CDN_PREFIX}/web/303679cce4f751ce.png`
const MultipleLanguages = memo(() => {
  const [isPC, setIsPC] = useState(true)
  const matches = useMediaQuery('@media (min-width:768px)')
  useEffect(() => {
    setIsPC(matches)
  }, [matches])
  return (
    <Box className="h-[400px] md:h-[483px] box-border w-full flex justify-end overflow-hidden p-2 md:py-[40px] relative">
      <LazyImg
        url={isPC ? bgImg : mobileBgImg}
        fill
        useCurrentUrl
        className="w-full h-full md:!w-[879px] md:!h-[483px] p-2 absolute !inset-[unset] !top-0 !left-0 md:!left-10 rounded-2xl md:rounded-[40px] overflow-hidden md:scale-[1.2]"
      />
      <Box className="w-full max-w-[443px] shrink-0 z-[2] md:mr-[10%] p-4 pb-8 flex flex-col justify-end md:justify-center">
        <LeftItem content="Hi, I'm Alice. I'm here to assist you in discovering apartments.😊" />
        <RightItem content="¿Se permiten mascotas en esta casa?" />
        <LeftItem content="Por supuesto, se pueden tener gatos y perros, se requiere pagar un depósito." />
        <RightItem content="안녕하세요 !" />
        <LeftItem content="당신이 원하는 숙소를 찾아드릴 수 있어요." />
      </Box>
    </Box>
  )
})
MultipleLanguages.displayName = 'MultipleLanguages'
export default MultipleLanguages
