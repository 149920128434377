import {
  ChangeEvent,
  FC,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState
} from 'react'
import { Box, CircularProgress, InputBase, Typography } from '@mui/material'
import SearchIcon from '@/components/icons/search'
import BackIcon from '@/components/icons/newHome/homeInput/back'
import { debounce } from 'lodash'
import { setSuggestionInStorage } from '@/lib/storage'
import Clear from '@/components/icons/newHome/homeInput/clear'
import { useUpdateEffect } from 'ahooks'
import { flushSync } from 'react-dom'
import { statsigLog } from '@/lib/statsigAndGa'
import { useIntl } from 'react-intl'
import { LoadingButton } from '@mui/lab'
import { getGeoLoading } from '@/state/loading'
import { useReactiveVar } from '@apollo/client'
import SuggestionsPopper from './suggestionsPopper'
import {
  CHAT_INPUT_PLACEHOLDER,
  RECORD_SEARCH_TYPE_ENUM,
  SuggestionsDataProps,
  useHomeSearch,
  useRecordSearch
} from './input'
import SuggestionsCard from './suggestionsCard'
import { getVID, transIdToInt } from './util'
import { gotoPage } from '../useChat'
import InputRecommendShow, { InputRecommendShowRef } from './inputRecommendShow'

interface HomeSearchProps {
  className?: string
  recommend: { latestSearch: string; suggestions: SuggestionsDataProps[] }
  recent: SuggestionsDataProps[]
  popular: SuggestionsDataProps[]
}

const MAX_TEXT_LEN = 200

const HomeSearch: FC<HomeSearchProps> = props => {
  const intl = useIntl()
  const { className = '', recommend, recent, popular } = props
  const popoverId = useId()
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const [text, setText] = useState<string>('')
  const isLoading = useReactiveVar(getGeoLoading)
  const [popperOpen, setPopperOpen] = useState<boolean>(false)
  const [showRecommend, setShowRecommend] = useState<boolean>(false)
  const [isRecommendMode, setIsRecommendMode] = useState<boolean>(false)
  const [isMobileStyle, setIsMobileStyle] = useState<boolean>(false)
  const [showRecentAndPopular, setShowRecentAndPopular] = useState<boolean>(
    recent.length > 0 || popular.length > 0
  )

  const inputRef = useRef<HTMLInputElement>()
  const containerRef = useRef<HTMLDivElement>()
  const inputRecommendShowRef = useRef<InputRecommendShowRef>()

  const { suggestions, setSuggestions, getSearchSuggestions } = useHomeSearch()
  const { recordSearch } = useRecordSearch()

  const checkInteractionMode = useCallback(
    (force = false) => {
      const dom = document.getElementById('mobile-href')
      let isMobile = false
      if (getComputedStyle && getComputedStyle(dom).display === 'block') {
        // 移动端样式
        if (force) {
          isMobile = true
        } else if (text.length > 0) {
          isMobile = true
        }
      }
      if (isMobile) {
        flushSync(() => setIsMobileStyle(true))
        setTimeout(() => {
          dom.parentElement.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        }, 1000)
      }
    },
    [text]
  )

  const requestSuggestions = useMemo(
    () =>
      debounce((input: string) => {
        if (input?.trim().length > 0) {
          getSearchSuggestions({
            variables: {
              input
            }
          })
        }
      }, 600),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const searchButtonAction = (
    type: RECORD_SEARCH_TYPE_ENUM,
    value: string | SuggestionsDataProps
  ) => {
    if (type === RECORD_SEARCH_TYPE_ENUM.DEFAULT) {
      setSuggestionInStorage({ type: 'text', value: value as string })
      recordSearch({
        variables: {
          input: {
            content: value,
            type,
            vid: getVID()
          }
        }
      }).finally(() => {
        gotoPage(value)
      })
    }
    if (type === RECORD_SEARCH_TYPE_ENUM.SUGGESTION) {
      setSuggestionInStorage({ type: 'suggestion', value: value as SuggestionsDataProps })
      recordSearch({
        variables: {
          input: {
            content: (value as SuggestionsDataProps)?.content ?? '',
            suggestion: value as SuggestionsDataProps,
            type,
            vid: getVID()
          }
        }
      }).finally(() => {
        gotoPage(value)
      })
    }
  }
  const handleSearchButton = () => {
    statsigLog('homepage_search_click')
    if (isRecommendMode) {
      if (inputRecommendShowRef?.current?.isSuggestionMode) {
        if (Array.isArray(recommend.suggestions) && recommend.suggestions.length > 0) {
          const selectRecommend =
            recommend.suggestions[inputRecommendShowRef.current.getSuggestionIndex()]
          searchButtonAction(RECORD_SEARCH_TYPE_ENUM.SUGGESTION, transIdToInt(selectRecommend))
          statsigLog('chat_send', {
            type: 'homepage',
            content: selectRecommend.content
          })
        }
      } else {
        searchButtonAction(RECORD_SEARCH_TYPE_ENUM.DEFAULT, recommend.latestSearch)
        statsigLog('chat_send', {
          type: 'homepage',
          content: recommend.latestSearch
        })
      }
    } else {
      searchButtonAction(RECORD_SEARCH_TYPE_ENUM.DEFAULT, text)
      statsigLog('chat_send', {
        type: 'homepage',
        content: text
      })
    }
  }

  const handleKeyDown: KeyboardEventHandler<HTMLTextAreaElement | HTMLInputElement> = e => {
    if (String(e.key).toLowerCase() === 'enter') {
      e.preventDefault()
      handleSearchButton()
    }
  }

  const handleClear = e => {
    e.stopPropagation()
    flushSync(() => setText(''))
    setIsRecommendMode(false)
    setShowRecommend(false)
    setSuggestions([])
    containerRef.current.style.boxShadow = '0px 0px 7px 0px rgba(255,255,255,0.6)'
    inputRef.current.focus()
    statsigLog('search_input_clear_click')
  }

  const handleContainerClick = e => {
    if (!showRecommend || document.activeElement === inputRef.current) {
      return
    }
    e.stopPropagation()
    setAnchorEl(containerRef.current)
    checkInteractionMode(true)
    inputRef.current.focus()
  }

  const handleMobileBack = e => {
    e.stopPropagation()
    setIsMobileStyle(false)
    setSuggestions([])
    setIsRecommendMode(true)
    setShowRecommend(true)
    setText('')
    setPopperOpen(false)
  }

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > MAX_TEXT_LEN || e.target.value === text) {
      return
    }
    if (isRecommendMode) {
      setIsRecommendMode(false)
    }
    if (showRecommend) {
      setShowRecommend(false)
    }
    if (e.target.value === '') {
      setSuggestions([])
      setText(e.target.value)
      setShowRecentAndPopular(true)
      setPopperOpen(true)
      return
    }
    setText(e.target.value)
    setShowRecentAndPopular(false)
    requestSuggestions.cancel()
    requestSuggestions(e.target.value)
  }

  const onInputFocus = () => {
    containerRef.current.style.boxShadow = '0px 0px 7px 0px rgba(255,255,255,0.6)'
    if (inputRef.current) {
      if (text?.length === 0) {
        // 如果显示了推荐 且text长度为0 则进入recommendMode
        // recommendMode 分为suggestion mode 和lastSearch mode
        if (showRecommend) {
          setIsRecommendMode(true)
          // 如果是分为suggestion mode
          if (inputRecommendShowRef.current && inputRecommendShowRef.current.isSuggestionMode) {
            flushSync(() =>
              setText(
                recommend.suggestions[inputRecommendShowRef.current.getSuggestionIndex()].content
              )
            )
            // 回填后弹最近和推荐
            if (recent.length > 0 || popular.length > 0) {
              setShowRecentAndPopular(true)
              setPopperOpen(true)
            }
          } else {
            // 如果是lastSearch mode
            flushSync(() => setText(recommend.latestSearch))
            setShowRecentAndPopular(false)
            requestSuggestions(recommend.latestSearch)
          }
          clickLog()
          // 推荐只在进入的时候显示一次 移动端每次back都需要显示
          setShowRecommend(false)
        } else {
          setShowRecentAndPopular(true)
          setPopperOpen(true)
        }
      } else if (isRecommendMode) {
        // 如果用户没有改变回填的内容 则还是处于recommend模式
        setShowRecentAndPopular(true)
        setPopperOpen(true)
        clickLog()
      } else if (suggestions.length > 0) {
        setPopperOpen(true)
        clickLog()
      }
      // 聚焦 光标移动到最后
      inputRef.current.focus()
    }
  }

  const onInputBlur = () => {
    containerRef.current.style.boxShadow = 'none'
  }
  const clickLog = () => {
    statsigLog('search_suggest_content_show', {
      contentShow: (showRecentAndPopular ? [...recent, ...popular] : suggestions)?.map(
        ({ content }) => content
      )
    })
  }
  useUpdateEffect(() => {
    if (inputRef.current !== document.activeElement) {
      // 未focus直接return
      return
    }
    if (showRecentAndPopular) return
    if (suggestions.length > 0) {
      setPopperOpen(true)
      clickLog()
      return
    }
    setPopperOpen(false)
  }, [suggestions])

  useEffect(() => {
    const temp = () => checkInteractionMode()
    window.addEventListener('resize', temp)
    return () => {
      window.removeEventListener('resize', temp)
    }
  }, [checkInteractionMode])

  useEffect(() => {
    if (isMobileStyle) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [isMobileStyle])

  useEffect(() => {
    setAnchorEl(containerRef.current)
  }, [])

  useEffect(() => {
    let recommendMode = false
    if (recommend) {
      recommendMode =
        !!recommend?.latestSearch ||
        (Array.isArray(recommend?.suggestions) && recommend.suggestions.length > 0)
    }
    setShowRecommend(recommendMode)
    setIsRecommendMode(recommendMode)
  }, [recommend])

  return (
    <Box
      className={`${
        isMobileStyle
          ? 'fixed top-0 left-0 w-full h-[var(--doc-h)] z-[1400] overflow-hidden overscroll-contain bg-white py-4'
          : 'w-full px-5 md:min-w-[760px] md:w-1/2 md:mx-auto'
      } ${className}`}
      component="div"
    >
      <Box component="div" id="mobile-href" className="md:hidden" />
      <Box
        component="div"
        className={`${
          isMobileStyle ? 'flex flex-row justify-between items-center gap-2 px-5' : ''
        }`}
      >
        {isMobileStyle && <BackIcon className="-ml-1" onClick={handleMobileBack} />}
        <Box
          component="div"
          className={`flex flex-row justify-between ${
            isMobileStyle
              ? 'w-full rounded-xl flex-1 border border-[#00A972] overflow-hidden'
              : `gap-2`
          }`}
        >
          <Box
            component="div"
            ref={containerRef}
            aria-describedby={popoverId}
            className={`relative gap-2 flex-1 flex flex-row border border-white rounded-xl ${
              isMobileStyle ? 'px-2 py-1' : 'px-4 py-2 h-12'
            }`}
            onClick={handleContainerClick}
          >
            <Box component="div" className={`relative w-full ${isMobileStyle ? 'h-[30px]' : ''}`}>
              <InputBase
                value={text}
                inputRef={inputRef}
                onKeyDown={handleKeyDown}
                onBlur={onInputBlur}
                onFocus={e => {
                  e.stopPropagation()
                  onInputFocus()
                }}
                onChange={onInputChange}
                placeholder={showRecommend ? '' : CHAT_INPUT_PLACEHOLDER}
                inputProps={{ 'aria-label': 'home input' }}
                className={`w-full whitespace-nowrap ${
                  isMobileStyle ? 'text-sm text-primary' : 'text-base text-white'
                }`}
              />
              {showRecommend && (
                <InputRecommendShow
                  ref={inputRecommendShowRef}
                  show={showRecommend}
                  recommend={recommend}
                  isMobileStyle={isMobileStyle}
                />
              )}
            </Box>
            {!showRecommend && text?.length > 0 && (
              <Box className="flex flex-row items-center gap-2 text-xs font-averta text-gray-400 leading-8">
                {text.length > 0 && <Clear onClick={handleClear} className="cursor-pointer" />}{' '}
                {!isMobileStyle && (
                  <span>
                    {text?.length}/{MAX_TEXT_LEN}
                  </span>
                )}
              </Box>
            )}
            <Box
              component="div"
              className={`md:hidden self-center ${isMobileStyle ? 'hidden' : ''}`}
              onClick={() => statsigLog('homepage_search_click')}
            >
              <SearchIcon />
            </Box>
          </Box>
          <LoadingButton
            disabled={isLoading}
            startIcon={
              isLoading ? (
                <CircularProgress
                  size={16}
                  className="text-white"
                  sx={{ verticalAlign: 'middle' }}
                />
              ) : (
                <SearchIcon />
              )
            }
            onClick={e => {
              e.stopPropagation()
              handleSearchButton()
            }}
            className={`md:flex items-center min-w-fit md:rounded-xl md:px-8 md:py-3 bg-[#00A972] hover:bg-[#00875B] ${
              isMobileStyle ? 'rounded-none block px-3' : 'rounded-60 hidden'
            }`}
          >
            <Typography className="hidden md:block ml-1 capitalize text-white font-averta text-sm w-[54px] overflow-hidden">
              {isLoading ? 'Loading' : intl.formatMessage({ id: 'home.input.search.button' })}
            </Typography>
          </LoadingButton>
        </Box>
      </Box>
      {isMobileStyle ? (
        <SuggestionsCard
          showRecentAndPopular={showRecentAndPopular}
          recent={recent}
          popular={popular}
          suggestions={suggestions}
        />
      ) : (
        <SuggestionsPopper
          id={popoverId}
          open={popperOpen}
          anchorEl={anchorEl}
          showRecentAndPopular={showRecentAndPopular}
          recent={recent}
          popular={popular}
          suggestions={suggestions}
          onClose={() => {
            if (inputRef.current !== document.activeElement) {
              setPopperOpen(false)
            }
          }}
        />
      )}
    </Box>
  )
}

export default HomeSearch
