import { SvgIcon } from '@mui/material'

const Efficient = props => (
  <SvgIcon {...props} width="31" height="30" viewBox="0 0 31 30" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3488 5.09293C17.2531 4.10643 18.8856 4.90999 18.6554 6.22828L17.5278 12.6867H23.9163C25.0851 12.6867 25.6931 14.0791 24.8986 14.9363L14.8637 25.7645C13.9166 26.7865 12.2328 25.8627 12.5858 24.5148L14.4592 17.362H8.14667C6.98135 17.362 6.37198 15.9768 7.15942 15.1178L16.3488 5.09293ZM16.9147 6.85401L8.75558 15.7549H14.8062C15.6837 15.7549 16.3241 16.5846 16.1018 17.4335L14.4112 23.8884L23.3029 14.2938H17.2091C16.3773 14.2938 15.7467 13.5436 15.8898 12.7242L16.9147 6.85401Z"
      fill="white"
    />
  </SvgIcon>
)
export default Efficient
