const ZipCodeLinear = props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_13957_51769)">
      <path
        d="M17.7762 16.9444V8.45428C17.7762 8.05095 17.3232 7.81361 16.9916 8.04319L12.2207 11.3462M17.7762 16.9444H3.33179M17.7762 16.9444L12.2207 11.3462M3.33179 16.9444V8.45428C3.33179 8.05095 3.78478 7.81361 4.11639 8.04319L8.88734 11.3462M3.33179 16.9444L8.88734 11.3462M8.88734 11.3462L10.554 12.5L12.2207 11.3462"
        stroke="url(#paint0_linear_13957_51769)"
        strokeWidth="1.5"
      />
      <path
        d="M15.5541 8.6111V3.55554C15.5541 3.2794 15.3302 3.05554 15.0541 3.05554H6.05408C5.77793 3.05554 5.55408 3.2794 5.55408 3.55554V8.6111"
        stroke="url(#paint1_linear_13957_51769)"
        strokeWidth="1.5"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_13957_51769"
        x1="10.554"
        y1="7.5"
        x2="10.554"
        y2="16.9444"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#8B8B8B" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_13957_51769"
        x1="10.5541"
        y1="3.05554"
        x2="10.5541"
        y2="8.6111"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#F9F9F9" />
      </linearGradient>
      <clipPath id="clip0_13957_51769">
        <rect width="20" height="20" fill="white" transform="translate(-0.00146484)" />
      </clipPath>
    </defs>
  </svg>
)
export default ZipCodeLinear
