import { FC } from 'react'

import { Box } from '@mui/material'
import SuggestionsContent, { SuggestionContentProps } from './suggestionsContent'

type SuggestionsCardProps = SuggestionContentProps

const SuggestionsCard: FC<SuggestionsCardProps> = props => {
  const { suggestions, recent, popular, showRecentAndPopular } = props
  return (
    <Box component="div" className="mt-5 px-5 overflow-auto h-[calc(var(--doc-h)_-_93px)]">
      <SuggestionsContent
        isMobileStyle
        recent={recent}
        popular={popular}
        suggestions={suggestions}
        showRecentAndPopular={showRecentAndPopular}
      />
    </Box>
  )
}

export default SuggestionsCard
