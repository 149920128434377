/* eslint-disable react/jsx-key */
import { FC, Fragment } from 'react'
import { Box, Divider, Typography } from '@mui/material'

import { setSuggestionInStorage } from '@/lib/storage'
import { omit } from 'lodash'
import CityOrArea from '@/components/icons/newHome/homeInput/cityOrArea'
import CityOrAreaLinear from '@/components/icons/newHome/homeInput/cityOrAreaLinear'
import Location from '@/components/icons/newHome/homeInput/location'
import LocationLinear from '@/components/icons/newHome/homeInput/locationLinear'
import Property from '@/components/icons/newHome/homeInput/property'
import PropertyLinear from '@/components/icons/newHome/homeInput/propertyLinear'
import Recent from '@/components/icons/newHome/homeInput/recent'
import RecentLinear from '@/components/icons/newHome/homeInput/recentLinear'
import ZipCode from '@/components/icons/newHome/homeInput/zipCode'
import ZipCodeLinear from '@/components/icons/newHome/homeInput/zipCodeLinear'
import { statsigLog } from '@/lib/statsigAndGa'
import GeolocationLinear from '@/components/icons/newHome/homeInput/geolocationLinear'
import Geolocation from '@/components/icons/newHome/homeInput/geolocation'
import { gotoPage } from '../useChat'
import { getVID, replaceHighlight, transIdToInt } from './util'
import useGetGeolocation from './useGetGeolocation'
import {
  RECORD_SEARCH_TYPE_ENUM,
  SUGGESTION_TYPE_ENUM,
  SuggestionsDataProps,
  useRecordSearch
} from './input'

export interface SuggestionContentProps {
  suggestions: SuggestionsDataProps[]
  recent?: SuggestionsDataProps[]
  popular?: SuggestionsDataProps[]
  showRecentAndPopular?: boolean
  isMobileStyle?: boolean
}

const SuggestionsContent: FC<SuggestionContentProps> = props => {
  const { suggestions, recent, popular, showRecentAndPopular, isMobileStyle } = props
  const { recordSearch } = useRecordSearch()
  const getGeoLocationSuccess = ({ city }) => {
    const msgText = `Help me find apartments in ${city}`
    const tempSuggestion = {
      suggestionType: SUGGESTION_TYPE_ENUM.PREFERENCE,
      content: msgText,
      messageContent: msgText,
      highlight: msgText,
      ext: {
        filter: {
          cityEqualTo: city
        }
      }
    } as SuggestionsDataProps
    setSuggestionInStorage({
      type: 'suggestion',
      value: tempSuggestion
    })
    recordSearch({
      variables: {
        input: {
          content: city,
          suggestion: transIdToInt(tempSuggestion),
          type: RECORD_SEARCH_TYPE_ENUM.SUGGESTION,
          vid: getVID()
        }
      }
    }).finally(() => {
      gotoPage(city)
    })
  }
  const { handleGetGeolocation } = useGetGeolocation({
    successCallback: getGeoLocationSuccess
  })

  const onItemClick = (suggestion: SuggestionsDataProps) => {
    setSuggestionInStorage({ type: 'suggestion', value: omit(suggestion, 'recent') })
    recordSearch({
      variables: {
        input: {
          content: suggestion.content ?? '',
          suggestion: transIdToInt(suggestion),
          type: RECORD_SEARCH_TYPE_ENUM.SUGGESTION,
          vid: getVID()
        }
      }
    }).finally(() => {
      gotoPage(suggestion)
    })
  }
  const clickLog = contentDetail => {
    statsigLog('search_suggest_content_click', {
      contentDetail
    })
  }
  return (
    <Box
      component="div"
      className={`flex flex-col gap-4 ${
        isMobileStyle ? 'overflow-auto' : 'p-4 shadow-[0_4px_36px_0px_rgba(0,0,0,0.08)]'
      }`}
    >
      <SuggestionItem
        highlight=""
        // SUGGESTION_TYPE_ENUM.GEO_LOCATION仅用于icon，
        // 发生请求使用的是SUGGESTION_TYPE_ENUM.CITY_NAME
        suggestionType={SUGGESTION_TYPE_ENUM.GEO_LOCATION}
        content="Search homes near me"
        onClick={() => {
          handleGetGeolocation()
        }}
        isMobileStyle={isMobileStyle}
      />
      {showRecentAndPopular && (
        <>
          {recent.length > 0 && (
            <>
              <Typography
                className={`${
                  isMobileStyle ? 'text-primary' : 'text-white'
                } font-averta font-semibold text-base -mb-2`}
              >
                Recent Searches
              </Typography>
              {recent.map((item, index) => (
                <Fragment key={item.content}>
                  <SuggestionItem
                    {...item}
                    recent
                    onClick={() => {
                      onItemClick(item)
                      clickLog(item.content)
                    }}
                    isMobileStyle={isMobileStyle}
                  />
                  {index !== recent.length - 1 && (
                    <Divider
                      className={`-my-2 ${isMobileStyle ? 'border-gray-200' : 'bg-white/10'}`}
                      component="li"
                      flexItem
                    />
                  )}
                </Fragment>
              ))}
            </>
          )}
          {popular.length > 0 && (
            <>
              <Typography
                className={`${
                  isMobileStyle ? 'text-primary' : 'text-white'
                } font-averta font-semibold text-base -mb-2`}
              >
                Popular
              </Typography>
              {popular.map((item, index) => (
                <Fragment key={item.content}>
                  <SuggestionItem
                    {...item}
                    onClick={() => {
                      onItemClick(item)
                      clickLog(item.content)
                    }}
                    isMobileStyle={isMobileStyle}
                  />
                  {index !== popular.length - 1 && (
                    <Divider
                      className={`-my-2 ${isMobileStyle ? 'border-gray-200' : 'bg-white/10'}`}
                      component="li"
                      flexItem
                    />
                  )}
                </Fragment>
              ))}
            </>
          )}
        </>
      )}
      {!showRecentAndPopular &&
        suggestions.length > 0 &&
        suggestions.map((item, index) => (
          <Fragment key={item.content}>
            <SuggestionItem
              {...item}
              onClick={() => {
                onItemClick(item)
                clickLog(item.content)
              }}
              isMobileStyle={isMobileStyle}
            />
            {index !== suggestions.length - 1 && (
              <Divider
                className={`-my-2 ${isMobileStyle ? 'border-gray-200' : 'bg-white/10'}`}
                component="li"
                flexItem
              />
            )}
          </Fragment>
        ))}
    </Box>
  )
}

const getSuggestionIcon = (
  suggestionType: SUGGESTION_TYPE_ENUM,
  isMobileStyle: boolean,
  recent = false
) => {
  if (recent) {
    return isMobileStyle ? <Recent /> : <RecentLinear />
  }
  switch (suggestionType) {
    case SUGGESTION_TYPE_ENUM.GEO_LOCATION:
      return isMobileStyle ? <Geolocation /> : <GeolocationLinear />
    case SUGGESTION_TYPE_ENUM.ADDRESS:
      return isMobileStyle ? <Location /> : <LocationLinear />
    case SUGGESTION_TYPE_ENUM.PROPERTY_NAME:
      return isMobileStyle ? <Property /> : <PropertyLinear />
    case SUGGESTION_TYPE_ENUM.ZIP_CODE:
      return isMobileStyle ? <ZipCode /> : <ZipCodeLinear />
    case SUGGESTION_TYPE_ENUM.CITY_NAME:
    case SUGGESTION_TYPE_ENUM.AREA_NAME:
      return isMobileStyle ? <CityOrArea /> : <CityOrAreaLinear />
    default:
      return isMobileStyle ? <Location /> : <LocationLinear />
  }
}

const SuggestionItem: FC<
  SuggestionsDataProps & { recent?: boolean; isMobileStyle: boolean; onClick: () => void }
> = props => {
  const { highlight, content, suggestionType, isMobileStyle, recent, onClick } = props
  return (
    <Box
      component="div"
      className={`flex justify-start items-center py-1 px-2 ${
        isMobileStyle ? '' : 'hover:rounded-lg hover:bg-[#FFFFFF26]'
      }`}
    >
      <Box
        component="div"
        className={
          isMobileStyle
            ? 'w-11 h-11 flex items-center justify-center p-3 rounded-xl bg-gray-100'
            : 'py-1 text-white'
        }
      >
        {getSuggestionIcon(suggestionType, isMobileStyle, recent)}
      </Box>
      <Box
        component="div"
        onClick={onClick}
        className="ml-3 flex-1  flex flex-row items-center cursor-pointer"
      >
        <Typography
          className={` font-averta ${isMobileStyle ? 'text-primary' : 'text-white'}`}
          dangerouslySetInnerHTML={{ __html: replaceHighlight(highlight || content || '') }}
        />
      </Box>
    </Box>
  )
}

export default SuggestionsContent
