import { FC } from 'react'
import { Box } from '@mui/material'
import Head from 'next/head'
import { RentyAIProperty } from '@/interface/property'
import moment from 'moment'
import { getDetailPageUrlPath } from '@/lib/urlParse'

interface HomeSeoProps {
  properties: RentyAIProperty[]
  locale: string
}

const HomeSeo: FC<HomeSeoProps> = ({ properties, locale = 'en' }) => {
  const title = 'Best & Affordable Apartments and Homes for Rent | Renty.AI'
  const desc = `Explore top affordable rentals, featuring apartments and student housing. Compare prices, view aerials, and secure your dream home hassle-free on Renty.AI.`

  const organizationSchema = {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    name: 'Renty.AI',
    logo: `${process.env.CDN_PREFIX}/web/cc20405814927df6.png`,
    url: 'https://renty.ai'
  }

  const aggregateOffer = ({ minPrice, maxPrice, url }) => ({
    '@type': 'AggregateOffer',
    url,
    priceCurrency: 'USD',
    lowPrice: minPrice,
    highPrice: maxPrice,
    offerCount: 2,
    availability: 'https://schema.org/InStock',
    validFrom: moment.utc().format()
  })

  const placeSchema = ({
    name: pName,
    address,
    city: pCity,
    area,
    zipoCode,
    latitude,
    longitude
  }) => ({
    '@context': 'http://schema.org',
    '@type': 'Place',
    name: pName,
    address: {
      '@type': 'PostalAddress',
      streetAddress: address,
      addressLocality: pCity,
      addressRegion: area,
      postalCode: zipoCode,
      addressCountry: 'US'
    },
    latitude,
    longitude
  })

  const videoSchema = (properties || [])
    .filter(({ videoUrl }) => videoUrl && videoUrl.length)
    .map(({ name, address, picList, videoUrl }) => ({
      '@context': 'http://schema.org',
      '@type': 'VideoObject',
      name: `YouTube Video - ${name || address}`,
      description: `Video tour around apartment for rent at ${address || name}`,
      thumbnailUrl: (picList && picList[0]?.url) || '',
      contentUrl: videoUrl[0].url,
      uploadDate: moment.utc().format('YYYY-MM-DD')
    }))

  const vrSchema = (properties || [])
    .filter(({ vrUrl, isVr, name }) => (vrUrl || isVr) && name)
    .map(
      ({
        name: vrName,
        picList: vrPicList,
        city: vrCity,
        area: vrArea,
        minPrice,
        maxPrice,
        id,
        address,
        zipoCode,
        latitude,
        longitude
      }) => ({
        '@context': 'http://schema.org',
        '@type': 'Event',
        name: `3D Tour - ${vrName}`,
        url: `https://renty.ai${getDetailPageUrlPath(vrCity, vrArea, vrName, id)}`,
        description: `${vrName} 3D Tour`,
        image: (vrPicList && vrPicList[0]?.url) || '',
        about: `${vrName} 3D Tour`,
        startDate: moment.utc().format('YYYY-MM-DD'),
        endDate: moment.utc().format('YYYY-MM-DD'),
        eventAttendanceMode: 'https://schema.org/MixedEventAttendanceMode',
        eventStatus: 'https://schema.org/EventScheduled',
        location: [
          {
            '@type': 'VirtualLocation',
            url: `https://renty.ai${getDetailPageUrlPath(vrCity, vrArea, vrName, id)}`
          },
          placeSchema({
            name: vrName,
            city: vrCity,
            area: vrArea,
            address,
            zipoCode,
            latitude,
            longitude
          })
        ],
        offers: aggregateOffer({
          minPrice,
          maxPrice,
          url: `https://renty.ai${getDetailPageUrlPath(vrCity, vrArea, vrName, id)}`
        }),
        performer: organizationSchema,
        organizer: organizationSchema
      })
    )

  const schemaData = {
    '@type': 'SearchResultsPage',
    '@context': 'http://schema.org',
    contentLocation: {
      '@type': 'City',
      name: ''
    },
    about: (properties || []).map(
      ({
        city: propertyCity,
        area,
        address,
        zipoCode,
        picList,
        id,
        name,
        introduce,
        phone,
        latitude,
        longitude
      }) => ({
        '@type': 'ApartmentComplex',
        '@id': `https://renty.ai${getDetailPageUrlPath(propertyCity, area, name, id)}`,
        name: name || address,
        url: `https://renty.ai${getDetailPageUrlPath(propertyCity, area, name, id)}`,
        description: introduce,
        telephone: phone || '',
        image: picList && picList[0]?.url,
        geo: { '@type': 'GeoCoordinates', latitude, longitude },
        address: {
          '@type': 'PostalAddress',
          streetAddress: address,
          addressLocality: propertyCity,
          addressRegion: area,
          postalCode: zipoCode,
          addressCountry: 'US'
        }
      })
    ),
    // when value not exist , so not add the key use JSON.parse(JSON.stringify())
    video: videoSchema.length ? videoSchema : undefined,
    tour: vrSchema.length ? vrSchema : undefined
  }

  const schemas = []
  if (properties?.length) {
    schemas.push(JSON.parse(JSON.stringify(schemaData)))
  }

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta data-n-head="ssr" name="description" content={desc} />
        <meta
          name="keywords"
          content="Renty.AI, AI rental platform, young professionals, personalized recommendations, intelligent rental search, AI-driven renting, seamless tenant screening, rental property"
        />
        <meta name="author" content="Renty.AI" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />
        <meta property="og:url" content="https://renty.ai" />
        <meta property="og:site_name" content="Renty.AI" />
        <meta property="og:image" content={`${process.env.CDN_PREFIX}/web/3789de2d6279bb80.png`} />
        <meta property="og:image:alt" content="Home is Renty.AI" />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="315" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={desc} />
        <meta name="twitter:site" content="@renty_ai" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <link rel="canonical" href={`https://renty.ai${locale === 'en' ? '' : `/${locale}`}`} />
        {/* Add hreflang links */}
        <link rel="alternate" href="https://renty.ai" hrefLang="x-default" />
        <link rel="alternate" href="https://renty.ai" hrefLang="en" />
        <link rel="alternate" href="https://renty.ai/zh" hrefLang="zh" />
        <link rel="alternate" href="https://renty.ai/es" hrefLang="es" />
      </Head>
      <Box>
        {schemas.map((schema, index) => (
          <script
            suppressHydrationWarning
            id={`structured-data-${schema['@type'] || index}`}
            key={`structured-data-${schema['@type'] || index}`}
            data-testid={`seo-${schema['@type'] || index}-schema`}
            type="application/ld+json"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
          />
        ))}
      </Box>
    </>
  )
}

export default HomeSeo
