import Image from 'next/image'
import { Button, Box } from '@mui/material'
import Typo from '@mui/material/Typography'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { Pagination, Navigation } from 'swiper'
import Link from '@mui/material/Link'
import { generatePath } from '@/lib/urlParse'
import { statsigLog } from '@/lib/statsigAndGa'
import { useIntl } from 'react-intl'

const swiperListData = [
  {
    name: 'Los Angeles',
    img: `${process.env.CDN_PREFIX}/web/132acc42a8e2990a.jpg`
  },
  {
    name: 'Houston',
    img: `${process.env.CDN_PREFIX}/web/0f229583392a5688.jpg`
  },
  {
    name: 'Irving',
    img: `${process.env.CDN_PREFIX}/web/6f3761cdafa3b3b3.jpg`
  },
  {
    name: 'Killeen',
    img: `${process.env.CDN_PREFIX}/web/a6e297008b1a2974.jpg`
  },
  {
    name: 'Corpus Christi',
    img: `${process.env.CDN_PREFIX}/web/493fb1a614861fc5.jpg`
  }
]

const PopularCity = ({ isPC }) => {
  const loader = ({ src }) => `${src}?w=1200&q=75`
  const intl = useIntl()
  const onClick = () => {
    const homePageContainer = document.querySelector('#home-page-container')
    if (homePageContainer) {
      homePageContainer.scrollTo({ top: 0, behavior: 'smooth' })
    }
    statsigLog('homepage_popular_city_search_call_back_click')
  }

  return (
    <>
      <style jsx global>{`
        .city-swiper {
          --swiper-theme-color: #fff;
          --swiper-navigation-color: #004d34;
          position: relative;
          --swiper-navigation-size: 0;
        }
        .city-swiper > .swiper-button-next {
          position: absolute;
          top: 0;
          width: 128px;
          height: 100%;
          right: 20px;
          pointer-events: none;
        }
        .city-swiper > .swiper-button-next::before {
          background: #050a22;
          content: '';
          display: inline-block;
          width: 48px;
          height: 48px;
          border-radius: 50%;
          position: absolute;
          pointer-events: all;
          right: 3px;
        }
        .city-swiper > .swiper-button-next::after {
          font-weight: 700;
          color: #fff;
          font-size: 14px;
          position: absolute;
          pointer-events: all;
          right: 3px;
          transform: translateX(-20px);
        }
        .city-swiper > .swiper-button-disabled {
          display: none;
        }

        .city-swiper > .swiper-button-prev {
          position: absolute;
          top: 0;
          width: 128px;
          height: 100%;
          left: 20px;
          pointer-events: none;
        }
        .city-swiper > .swiper-button-prev::before {
          background: #050a22;
          content: '';
          display: inline-block;
          width: 48px;
          height: 48px;
          border-radius: 50%;
          position: absolute;
          pointer-events: all;
          left: 3px;
        }
        .city-swiper > .swiper-button-prev::after {
          font-weight: 700;
          color: #fff;
          font-size: 14px;
          position: absolute;
          pointer-events: all;
          left: 3px;
          transform: translateX(20px);
        }
      `}</style>
      <Box className="bg-[#001229] pt-[50px] pb-8 md:py-[60px]">
        <Box className="relative w-full px-5 md:h-[520px] flex flex-col md:flex-col">
          <Box className="flex w-full md:h-full items-center max-w-[1160px] m-auto">
            <Box className="w-[365px] ">
              <Typo
                variant="h2"
                className="text-white font-averta text-2xl md:text-5xl font-semibold"
              >
                {intl.formatMessage({ id: 'home.popular.cities.title' })}
              </Typo>
              <Typo className="text-white font-averta pb-[64px] pt-4 md:pt-8">
                {intl.formatMessage({ id: 'home.popular.cities.description' })}
              </Typo>
              <Button
                className="hidden md:block bg-[#00A972] font-averta text-white px-[60px] py-3 rounded-[30px] normal-case font-bold text-base"
                onClick={onClick}
              >
                {intl.formatMessage({ id: 'home.popular.cities.button' })}
              </Button>
            </Box>
          </Box>
          <Box className="w-full h-[380px] md:hidden" />

          <Box
            className="h-[380px] md:h-full absolute rounded-l-[20px] overflow-hidden right-0 top-[184px] md:top-0"
            sx={{
              width: isPC ? '56%' : 'calc(100% - 20px)'
            }}
          >
            <Swiper
              slidesPerView={isPC ? 2.6 : 1.3}
              spaceBetween={4}
              navigation={isPC}
              modules={[Pagination, Navigation]}
              className="city-swiper relative rounded-l-[20px]"
            >
              {swiperListData.map(({ name: currentName, img: currentImage }) => (
                <SwiperSlide key={currentName} className="max-w-[786px]">
                  <Link
                    href={generatePath(currentName)}
                    onClick={() => {
                      statsigLog('homepage_popular_city_click', {
                        cityDetail: currentName
                      })
                    }}
                  >
                    <Box className="relative h-[380px] md:h-[520px] w-full">
                      <Image
                        loader={loader}
                        src={currentImage}
                        alt={`${currentName} Image`}
                        fill
                        style={{ objectFit: 'cover' }}
                      />
                      <Typo className="z-50 absolute bottom-6 md:bottom-10 left-6 md:left-10 text-white text-2xl md:text-[28px] font-averta font-semibold">
                        {currentName}
                      </Typo>
                    </Box>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
          <Button
            className="block md:hidden w-full mx-auto bg-[#00A972] font-averta text-white px-[60px] py-3 mt-[60px] rounded-[30px] normal-case font-bold text-base"
            onClick={onClick}
          >
            {intl.formatMessage({ id: 'home.popular.cities.button' })}
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default PopularCity
