import { showAlert } from '@/state/alert'
import { useReactiveVar } from '@apollo/client'
import { Alert, Snackbar } from '@mui/material'

const CustomAlert = () => {
  const { state, msg, type } = useReactiveVar(showAlert)

  return (
    <Snackbar
      open={state}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={() => showAlert({ state: false })}
    >
      <Alert onClose={() => showAlert({ state: false })} severity={type} sx={{ width: '100%' }}>
        {msg}
      </Alert>
    </Snackbar>
  )
}
CustomAlert.displayName = 'CustomAlert'
export default CustomAlert
