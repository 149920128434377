import { FC, useCallback, useEffect, useReducer } from 'react'
import { Popper } from '@mui/material'
import { ClickAwayListener } from '@mui/base'

import SuggestionsContent, { SuggestionContentProps } from './suggestionsContent'

interface SuggestionsPopperProps extends SuggestionContentProps {
  id: string
  open: boolean
  anchorEl: null | HTMLElement
  onClose: () => void
}

const SuggestionsPopper: FC<SuggestionsPopperProps> = props => {
  const { id, open, anchorEl, onClose, suggestions, recent, popular, showRecentAndPopular } = props
  const [, flushUpdate] = useReducer(x => x + 1, 0)

  const handleWidth = useCallback(() => {
    flushUpdate()
  }, [])
  useEffect(() => {
    handleWidth()
    window.addEventListener('resize', handleWidth)
    return () => {
      window.removeEventListener('resize', handleWidth)
    }
  }, [handleWidth])
  if (showRecentAndPopular && recent.length === 0 && popular.length === 0) {
    return null
  }
  if (!showRecentAndPopular && suggestions.length === 0) return null
  return (
    <ClickAwayListener onClickAway={onClose}>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom"
        className="z-10 bg-[#00000099] rounded-20"
        style={{
          width: anchorEl?.offsetWidth ?? '100%'
        }}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 8]
            }
          },
          {
            name: 'flip',
            enabled: false
          }
        ]}
      >
        <SuggestionsContent
          isMobileStyle={false}
          recent={recent}
          popular={popular}
          suggestions={suggestions}
          showRecentAndPopular={showRecentAndPopular}
        />
      </Popper>
    </ClickAwayListener>
  )
}

export default SuggestionsPopper
