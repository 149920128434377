import { Box } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css/navigation'
import { Pagination } from 'swiper'
import 'swiper/css'
import { useIntl } from 'react-intl'

const UserTrust = () => {
  const intl = useIntl()
  const TrustData = [
    {
      userName: 'Sarah Johnson',
      comment: `“${intl.formatMessage({ id: 'home.customer.reviews.content3' })}”`,
      dateText: intl.formatMessage({ id: 'home.customer.reviews.date3' })
    },
    {
      userName: 'James Wilson',
      comment: `“${intl.formatMessage({ id: 'home.customer.reviews.content1' })}”`,
      dateText: intl.formatMessage({ id: 'home.customer.reviews.date1' })
    },
    {
      userName: 'Emily Taylor',
      comment: `“${intl.formatMessage({ id: 'home.customer.reviews.content2' })}”`,
      dateText: intl.formatMessage({ id: 'home.customer.reviews.date2' })
    }
  ]
  return (
    <Box className="bg-white pt-[30px] pb-8 md:pt-8 md:mb-[60px]">
      <Box className="flex flex-col justify-center items-center">
        <Box className="h-full lg:h-fit w-full max-w-[1160px] flex flex-col md:items-center gap-6 md:gap-16">
          <Box className="text-primary text-[22px] md:text-5xl px-5 font-semibold">
            {intl.formatMessage({ id: 'home.customer.reviews.title' })}
          </Box>
          <Box className="hidden md:flex gap-8">
            {TrustData.map(({ userName, comment }) => (
              <Box
                key={userName.replace(/\s/g, '-')}
                className="w-[33%] bg-gray-100 rounded-[20px] p-[28px] flex flex-col justify-between gap-8"
              >
                <Box className="text-xl text-[#1C1B1F]">{comment}</Box>
                <Box className="flex justify-between text-lg">
                  <Box className="font-semibold">{userName}</Box>
                </Box>
              </Box>
            ))}
          </Box>
          <style jsx global>{`
            .comment-swiper {
              --swiper-theme-color: #fff;
              --swiper-navigation-color: #004d34;
              position: relative;
              --swiper-navigation-size: 0;
            }
            .comment-swiper .swiper-pagination-bullets {
              position: absolute;
              bottom: 0;
              z-index: 2;
            }
            .comment-swiper .swiper-pagination-bullet {
              height: 8px;
              width: 8px;
              border-radius: 8px;
              margin: 0 4px !important;
              transition: all 0.3s;
            }
            .comment-swiper .swiper-pagination-bullet-active {
              background: #004d34;
              width: 24px;
            }
          `}</style>
          <Swiper
            slidesPerView={1}
            pagination
            modules={[Pagination]}
            className="comment-swiper flex md:hidden w-full items-stretch pb-8"
          >
            {TrustData.map(({ userName, comment }) => (
              <SwiperSlide key={userName} className="w-full px-5">
                <Box
                  key={userName.replace(/\s/g, '-')}
                  className="w-full h-[350px] bg-gray-100 rounded-[20px] py-5 px-4 flex flex-col justify-between gap-8"
                >
                  <Box className="text-xl text-[#1C1B1F]">{comment}</Box>
                  <Box className="flex justify-between text-lg">
                    <Box className="font-semibold">{userName}</Box>
                  </Box>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>
    </Box>
  )
}

export default UserTrust
