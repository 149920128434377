import { Box } from '@mui/material'
import { RentyAIProperty } from '@/interface/property'
import { FC, RefObject, useEffect } from 'react'
import Head from 'next/head'
import { setSuggestionInStorage } from '@/lib/storage'
import { useIntl } from 'react-intl'
import Image from 'next/image'
import lozad from 'lozad'
import AiAvatar from '../icons/aiAvatarMobile'
import Dollar from '../icons/newHome/dollar'
import Verified from '../icons/newHome/verified'
import Security from '../icons/newHome/security'
import Efficient from '../icons/newHome/efficient'
import Smart from '../icons/newHome/smart'
import HomeSearch from './homeSearch'
import RecentViewed from './recentViewed'
import { SuggestionsDataProps } from './homeSearch/input'

const videoPoster = `${process.env.CDN_PREFIX}/web/fcd727f94b86d5b0.jpg`
const videoSource = `${process.env.CDN_PREFIX}/web/ae3c0c248807e644.mp4`
interface InputSearchProps {
  isPC: boolean
  recentViewedHouse: RentyAIProperty[]
  searchRecommend: { latestSearch: string; suggestions: SuggestionsDataProps[] }
  searchRecent: SuggestionsDataProps[]
  searchPopular: SuggestionsDataProps[]
  scrollFlagRef?: RefObject<HTMLElement>
}
const InputSearch: FC<InputSearchProps> = ({
  isPC,
  recentViewedHouse,
  searchRecommend,
  searchRecent,
  searchPopular,
  scrollFlagRef = null
}) => {
  const intl = useIntl()
  useEffect(() => {
    const observer = lozad()
    observer.observe()
    setSuggestionInStorage({ type: 'text', value: '' })
  }, [])

  return (
    <Box>
      <Head>
        <link rel="preload" fetchPriority="high" as="image" href={videoPoster} type="image/jpg" />
      </Head>
      {/* header title */}
      <Box
        className="w-full h-[calc(100vh_-_120px)] flex flex-col justify-center items-center relative py-[92px] md:pb-[200px] "
        ref={scrollFlagRef}
      >
        <Image
          src={videoPoster}
          alt="video poster"
          fill
          priority
          className="absolute w-full h-full object-cover -z-[51]"
        />
        <video
          className="lozad"
          autoPlay
          loop
          muted
          controls={false}
          playsInline
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            zIndex: -50,
            position: 'absolute',
            top: 0,
            left: 0
          }}
        >
          <source data-src={videoSource} type="video/mp4" />
        </video>
        <Box className="w-full max-w-[1160px] flex flex-col items-center gap-3 mb-8 md:mb-10 z-20">
          <AiAvatar className="text-4xl md:text-5xl" />
          <Box
            className="font-averta text-white text-[24px] md:text-[44px] font-bold flex flex-wrap justify-center"
            component="h1"
          >
            {intl.formatMessage({ id: 'home.input.search.title.section1' })}
            <Box
              className="text-[transparent] ml-2"
              sx={{
                backgroundImage: 'linear-gradient(90deg, #02BB7F 2.5%, #45F68C 95.38%)',
                backgroundClip: 'text'
              }}
            >
              {intl.formatMessage({ id: 'home.input.search.title.section2' })}
            </Box>
          </Box>
          <Box className="text-white text-sm w-[270px] md:w-full md:text-lg text-center">
            {intl.formatMessage({ id: 'home.input.search.description' })}
          </Box>
        </Box>
        <HomeSearch
          className="z-20"
          recommend={searchRecommend}
          recent={searchRecent}
          popular={searchPopular}
        />
      </Box>
      <RecentViewed isMobileDevice={!isPC} recentViewedHouse={recentViewedHouse} />
      {/* extra promotion */}
      <Box className="bg-[#F3FAF7] h-[175px] md:h-[168px] flex justify-center">
        <Box className="h-full lg:h-fit w-full max-w-[1160px] md:px-[88px] flex justify-center  md:justify-between items-center px-0">
          <Box className="mx-5 md:py-10 w-[135px] md:w-fit text-center md:text-start">
            <Dollar className="md:hidden text-[36px] md:text-[42px]" />
            <Box className="text-[#1D2129] text-base md:text-[32px] font-semibold md:leading-[56px]">
              <Dollar className="hidden md:inline text-[36px] md:text-[42px] mr-3" />
              {intl.formatMessage({ id: 'home.input.search.promotion1.title' })}
            </Box>
            <Box className="text-[#66976A] text-xs md:text-[20px] md:leading-8 ml-0 md:ml-[54px]">
              {intl.formatMessage({ id: 'home.input.search.promotion1.description' })}
            </Box>
          </Box>
          <Box className="block md:hidden w-[1px] h-20 bg-primary opacity-[0.1]" />
          <Box className="mx-5 md:py-10 w-[135px] md:w-fit text-center md:text-start">
            <Verified className="md:hidden text-[36px] md:text-[42px]" />
            <Box className="text-[#1D2129] text-base md:text-[32px] font-semibold md:leading-[56px]">
              <Verified className="hidden md:inline text-[42px] mr-3" />
              {intl.formatMessage({ id: 'home.input.search.promotion2.title' })}
            </Box>
            <Box className="text-[#66976A] text-xs md:text-[20px] md:leading-8 ml-0 md:ml-[54px]">
              {intl.formatMessage({ id: 'home.input.search.promotion2.description' })}
            </Box>
          </Box>
        </Box>
      </Box>
      {/* green bar */}
      <Box className="bg-[#2A9A76] flex justify-center mb-[30px] md:mb-[60px]">
        <Box className="h-fit w-full max-w-[1160px] flex flex-col md:flex-row justify-between items-center py-6 md:py-3">
          <Box className="text-white font-semibold text-[24px] leading-6 mb-6 md:mb-0 px-5 md:px-0">
            {intl.formatMessage({ id: 'home.input.search.green.bar.title' })}
          </Box>
          <Box className="text-white text-sm md:text-[20px] md:leading-7 flex items-center md:gap-16">
            <Box className="py-2 px-6 md:px-0 flex flex-col md:flex-row justify-center text-center items-center">
              <Security className="mr-0 md:mr-4 text-2xl md:text-[30px]" />

              {intl.formatMessage({ id: 'home.input.search.green.bar.content1' })}
            </Box>
            <Box className="block md:hidden w-[1px] h-10 bg-white opacity-[0.1] mx-2" />
            <Box className="py-2 px-6 md:px-0 flex flex-col md:flex-row justify-center text-center items-center">
              <Smart className="mr-0 md:mr-4 text-2xl md:text-[30px]" />

              {intl.formatMessage({ id: 'home.input.search.green.bar.content2' })}
            </Box>
            <Box className="block md:hidden w-[1px] h-10 bg-white opacity-[0.1] mx-2" />
            <Box className="py-2 px-6 md:px-0 flex flex-col md:flex-row justify-center text-center items-center">
              <Efficient className="mr-0 md:mr-4 text-2xl md:text-[30px]" />

              {intl.formatMessage({ id: 'home.input.search.green.bar.content3' })}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default InputSearch
