import { getIp } from '@/lib/getIp'
import { gql } from '@apollo/client'
import searchRecommendGQL from '@/graphqls/searchRecommend'
import searchHistoryAndPopularGQL from '@/graphqls/searchHistoryAndPopular'
import { gaLog, useStatsig } from '@/lib/statsigAndGa'
import { useEffect, useState } from 'react'
import { useMediaQuery } from '@mui/material'
import { initializeApollo } from '../lib/apolloClient'
import USER_RECENT_VIEWED_HOUSE from '../graphqls/rentyRecommendUserProperties.gql'
import getLocationFromIpAddress from '../graphqls/getLocationFromIpAddress.gql'
import GUESS_YOU_LIKE_HOUSE from '../graphqls/queryGuessYouLikeHouse.gql'
import { getIsMobile } from '../lib/getDevice'
import NewHome from '../components/newHome'

const HomePage = ({
  isMobileDevice = false,
  recentViewedHouse = [],
  searchRecommend = [],
  searchRecent = [],
  searchPopular = [],
  userIpCity = '',
  guessULikeList = []
}) => {
  const [isPc, setIsPc] = useState(!isMobileDevice)
  const matches = useMediaQuery('@media (min-width:768px)')
  useStatsig('homepage_page_view', {}, isMobileDevice)

  useStatsig('renty_ai_page_loaded', { page: 'newHome' }, isMobileDevice)
  useEffect(() => {
    // ga with statsig
    gaLog('renty_ai_page_loaded', {
      page: 'newHome'
    })
  }, [])

  useEffect(() => {
    setIsPc(matches)
  }, [matches])

  return (
    <NewHome
      isMobileDevice={!isPc}
      recentViewedHouse={recentViewedHouse}
      searchRecommend={searchRecommend}
      searchRecent={searchRecent}
      searchPopular={searchPopular}
      userIpCity={userIpCity}
      guessULikeList={guessULikeList}
    />
  )
}

const getServerSideProps = async ({ req }) => {
  const userId = req.cookies.statsig_id
  const apolloClient = initializeApollo(req)

  const isMobileDevice = getIsMobile(req)
  const ipAddress = getIp(req)

  let recentViewedHouse = []
  let searchRecommend = {
    latestSearch: '',
    suggestions: []
  }
  let searchRecent = []
  let searchPopular = []
  let userIpCity = ''
  let guessULikeList = []

  await Promise.allSettled([
    apolloClient.query({
      query: gql`
        ${USER_RECENT_VIEWED_HOUSE}
      `,
      variables: {
        request: {
          ipAddress,
          vid: userId
        }
      }
    }),
    apolloClient.query({
      query: searchRecommendGQL,
      variables: {
        input: {
          ip: ipAddress,
          vid: userId
        }
      }
    }),
    apolloClient.query({
      query: searchHistoryAndPopularGQL,
      variables: {
        input: {
          ip: ipAddress,
          vid: userId
        }
      }
    }),
    apolloClient.query({
      query: getLocationFromIpAddress,
      variables: {
        ip: ipAddress
      }
    }),
    apolloClient.query({
      query: GUESS_YOU_LIKE_HOUSE,
      variables: {
        filterBy: {
          channel: 'renty',
          uid: userId
        }
      }
    })
  ])
    .then(res => {
      if (res[0].status === 'fulfilled') {
        // 减少房源图片数量返回，只返回10张，减少html大小
        recentViewedHouse = (
          res[0].value.data?.rentyRecommendUserProperties?.recommendList?.data || []
        ).map(item => ({ ...item, picList: item.picList.slice(0, 10) }))
      }
      if (res[1].status === 'fulfilled') {
        searchRecommend = res[1].value.data?.queryRecommendedSearch || {
          latestSearch: '',
          suggestions: []
        }
      }
      if (res[2].status === 'fulfilled') {
        const { popularPreferences, recentSuggestions } = res[2].value.data
          ?.suggestionHistoryAndPopularSearch ?? {
          popularPreferences: [],
          recentSuggestions: []
        }
        searchRecent = Array.isArray(recentSuggestions) ? recentSuggestions : []
        searchPopular = Array.isArray(popularPreferences) ? popularPreferences : []
      }
      if (res[3].status === 'fulfilled') {
        const { city } = res[3].value.data?.getLocationFromIpAddress || { city: '' }
        userIpCity = city
      }
      if (res[4].status === 'fulfilled') {
        // 减少房源图片数量返回，只返回10张，减少html大小
        guessULikeList = (res[4]?.value.data?.queryRentyAIGuessYouLikeHouse?.data || []).map(
          item => ({ ...item, picList: item.picList.slice(0, 10) })
        )
      }
    })
    .catch(() => {
      // TODO
    })

  return {
    props: {
      isMobileDevice,
      uid: userId,
      ip: ipAddress,
      recentViewedHouse,
      searchRecommend,
      searchRecent,
      searchPopular,
      userIpCity,
      guessULikeList
    }
  }
}

export { getServerSideProps }

export default HomePage
