import { Box, useMediaQuery } from '@mui/material'
import Image from 'next/image'
import LazyImg from '@/components/img/lazyImg'
import { memo, useEffect, useState } from 'react'
import MessageItem from './messageItem'

const { LeftItem, RightItem } = MessageItem
const bgImg = `${process.env.CDN_PREFIX}/web/80f81a7cee06aa9d.png`
const mobileBgImg = `${process.env.CDN_PREFIX}/web/1d57ae770d030554.png`

const UnitList = () => {
  const UnitListData = [
    {
      url: `${process.env.CDN_PREFIX}/web/c6c4d1619bbfa822.png`,
      name: 'Unit 301'
    },
    {
      url: `${process.env.CDN_PREFIX}/web/dc3ee34be4ad2278.png`,
      name: 'Unit 302'
    },
    {
      url: `${process.env.CDN_PREFIX}/web/c5f06c91c65b1711.png`,
      name: 'Unit 303'
    }
  ]
  return (
    <Box className="w-full max-w-[350px] flex justify-start mt-4">
      <Box
        className="text-black text-sm md:text-lg font-normal p-2 rounded-lg border ml-10 bg-white w-full flex flex-col"
        sx={{
          boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.05)',
          borderColor: 'rgba(211, 221, 204, 0.60)'
        }}
      >
        {UnitListData.map(({ name, url }, idx) => (
          <Box key={name.replace(/\s/g, '-')}>
            {idx > 0 ? <hr className="my-2" /> : ''}
            <Box className="flex w-full gap-2">
              <Image
                className="w-[34px] h-[34px]"
                width={34}
                height={34}
                src={url}
                alt={name || 'renty img'}
              />
              <Box className="flex flex-col w-full gap-2">
                <Box className="text-sm font-semibold leading-[10px]">{name}</Box>
                <Box className="bg-[#F1F1F1] rounded-sm h-4 w-full" />
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  )
}
const IntelligentAIChat = memo(() => {
  const [isPC, setIsPC] = useState(true)
  const matches = useMediaQuery('@media (min-width:768px)')
  useEffect(() => {
    setIsPC(matches)
  }, [matches])
  return (
    <Box className="h-[400px] md:h-[483px] box-border w-full flex overflow-hidden p-2 md:py-[40px] relative">
      <Box className="w-full max-w-[508px] shrink-0 md:ml-[10%] z-[2] p-4 pb-8 flex flex-col justify-end md:justify-start">
        <LeftItem content="Hi, I'm Alice. I'm here to assist you in discovering apartments.😊" />
        <RightItem content="Help me find a unit in this property" />
        <LeftItem content="Sure, here are the units I found for you" />
        <UnitList />
      </Box>
      <LazyImg
        url={isPC ? bgImg : mobileBgImg}
        fill
        useCurrentUrl
        className="w-full h-full md:!w-[879px] md:!h-[483px] p-2 absolute !inset-[unset] !top-0 !right-0 md:!right-10 rounded-2xl md:rounded-[40px] overflow-hidden md:scale-[1.2]"
      />
    </Box>
  )
})
IntelligentAIChat.displayName = 'IntelligentAIChat'
export default IntelligentAIChat
