import { SvgIcon } from '@mui/material'

const VoiceIcon = props => (
  <SvgIcon {...props} width="25" height="25" viewBox="0 0 25 25" fill="none">
    <rect width="24.4" height="24.4" transform="translate(0.300049 0.5)" fill="white" />
    <path
      d="M14.3643 13.4632C14.3572 16.1139 13.3055 18.6549 11.4373 20.5352C11.3525 20.62 11.2519 20.6872 11.1412 20.7331C11.0305 20.7789 10.9118 20.8025 10.7919 20.8025C10.5498 20.8025 10.3177 20.7064 10.1465 20.5352C9.97536 20.364 9.8792 20.1319 9.8792 19.8898C9.8792 19.6478 9.97536 19.4156 10.1465 19.2444C11.6786 17.7105 12.5391 15.6312 12.5391 13.4632C12.5391 11.2953 11.6786 9.21594 10.1465 7.682C10.0623 7.59706 9.99563 7.49633 9.95039 7.38558C9.90515 7.27483 9.88222 7.15624 9.88292 7.03661C9.88222 6.91698 9.90515 6.79839 9.95039 6.68764C9.99563 6.57689 10.0623 6.47616 10.1465 6.39123C10.231 6.30603 10.3316 6.2384 10.4423 6.19225C10.5531 6.1461 10.6719 6.12235 10.7919 6.12235C10.9119 6.12235 11.0307 6.1461 11.1415 6.19225C11.2523 6.2384 11.3528 6.30603 11.4373 6.39123C13.3055 8.27155 14.3572 10.8126 14.3643 13.4632ZM16.4438 3.81877C17.7108 5.085 18.7159 6.5885 19.4017 8.24333C20.0875 9.89817 20.4404 11.6719 20.4404 13.4632C20.4404 15.2545 20.0875 17.0283 19.4017 18.6831C18.7159 20.3379 17.7108 21.8414 16.4438 23.1077C16.2726 23.2788 16.0404 23.375 15.7984 23.375C15.5563 23.375 15.3241 23.2788 15.153 23.1077C14.9818 22.9365 14.8857 22.7044 14.8857 22.4623C14.8857 22.2202 14.9818 21.9881 15.153 21.8169C17.3678 19.601 18.6119 16.5962 18.6119 13.4632C18.6119 10.3302 17.3678 7.32547 15.153 5.10954C15.0678 5.02504 15.0002 4.9245 14.954 4.81373C14.9079 4.70296 14.8841 4.58415 14.8841 4.46415C14.8841 4.34415 14.9079 4.22534 14.954 4.11457C15.0002 4.0038 15.0678 3.90327 15.153 3.81877C15.2375 3.73357 15.338 3.66594 15.4488 3.61979C15.5596 3.57364 15.6784 3.54989 15.7984 3.54989C15.9184 3.54989 16.0372 3.57364 16.1479 3.61979C16.2587 3.66594 16.3593 3.73357 16.4438 3.81877Z"
      fill="#001229"
    />
    <circle cx="6.4" cy="13.4625" r="1.525" fill="#001229" />
  </SvgIcon>
)
export default VoiceIcon
