const ZipCode = props => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_13567_100016)">
      <g id="Group 1160500426">
        <path
          id="Vector 1007"
          d="M16 15.25V7.70428C16 7.30095 15.547 7.06361 15.2154 7.29319L11 10.2115M16 15.25H3M16 15.25L11 10.2115M3 15.25V7.70428C3 7.30095 3.45299 7.06361 3.7846 7.29319L8 10.2115M3 15.25L8 10.2115M8 10.2115L9.5 11.25L11 10.2115"
          stroke="#717171"
          strokeWidth="1.4"
        />
        <path
          id="Rectangle 21790"
          d="M14 7.75V3.25C14 2.97386 13.7761 2.75 13.5 2.75H5.5C5.22386 2.75 5 2.97386 5 3.25V7.75"
          stroke="#717171"
          strokeWidth="1.4"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_13567_100016">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
export default ZipCode
