import { Box } from '@mui/material'
import { useIntl } from 'react-intl'
import { useMemo } from 'react'
import FaqContent from '../faq/faqContent'

const bgImg = 'https://tripalink-public.s3-accelerate.amazonaws.com/f9abcef28ef72de6.png'
const Faq = ({ isPC }) => {
  const intl = useIntl()
  const faqIntlList = useMemo(
    () =>
      Array(6)
        .fill(0)
        .map((_, key) => ({
          question: intl.formatMessage({ id: `home.faq.question${key + 1}` }),
          key: key + 1,
          answer: intl.formatMessage({ id: `home.faq.answer${key + 1}` })
        })),
    []
  )
  return (
    <Box className="bg-white py-[30px] md:py-[60px]">
      <Box className="flex flex-col justify-center items-center ">
        <Box
          className="relative h-full lg:h-fit w-full max-w-[1160px] flex flex-col md:items-center md:pb-[86px] bg-[#F0F8F4] md:rounded-[40px]"
          sx={{
            '&::before': isPC
              ? {
                  zIndex: 10,
                  content: '""',
                  width: '160px',
                  height: '154px',
                  display: 'block',
                  position: 'absolute',
                  left: '56px',
                  top: '56px',
                  backgroundImage: `url(${bgImg})`,
                  backgroundSize: 'contain',
                  transform: 'rotateZ(180deg)',
                  pointerEvents: 'none'
                }
              : {},
            '&::after': isPC
              ? {
                  zIndex: 10,
                  content: '""',
                  width: '160px',
                  height: '154px',
                  display: 'block',
                  position: 'absolute',
                  right: '56px',
                  bottom: '56px',
                  backgroundSize: 'contain',
                  backgroundImage: `url(${bgImg})`,
                  pointerEvents: 'none'
                }
              : {}
          }}
        >
          <Box className="text-primary text-[22px] px-5 py-6 md:pt-[56px] md:pb-5 md:text-5xl font-semibold">
            {intl.formatMessage({ id: 'home.faq.title' })}
          </Box>
          <FaqContent
            from="home"
            selectFaqKey={null}
            defaultExpandKeys={[1]}
            faqs={faqIntlList}
            className="!py-4 md:pt-9"
            sx={{
              '& .MuiAccordion-root': {
                background: 'transparent'
              }
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default Faq
