import { useEffect, FC, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, FreeMode } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/free-mode'
import { RentyAIProperty } from '@/interface/property'
import { Box, useMediaQuery } from '@mui/material'
import { useIntl } from 'react-intl'
import { setSuggestionInStorage } from '@/lib/storage'
import HouseCard from '../houseCard'
import { chatActiveProperty, chatActivePropertyList } from '../../state/chatActiveProperty'
import { gotoPage } from './useChat'
import { SUGGESTION_TYPE_ENUM, SuggestionsDataProps } from './homeSearch/input'

interface IRecommendListProps {
  isMobileDevice?: boolean
  recentViewedHouse?: RentyAIProperty[]
}

const RecentViewed: FC<IRecommendListProps> = ({
  isMobileDevice = false,
  recentViewedHouse = []
}) => {
  const intl = useIntl()
  const [slidesPerView, setSlidesPerView] = useState(isMobileDevice ? 1.3 : 3)

  const onClick = property => {
    chatActiveProperty(property)
    chatActivePropertyList(recentViewedHouse)
    const suggestion: SuggestionsDataProps = {
      suggestionType: SUGGESTION_TYPE_ENUM.PROPERTY_NAME,
      highlight: property.name,
      content: property.name,
      ext: {
        city: property.city,
        area: property.area,
        propertyID: property.id,
        propertyName: property.name
      }
    }
    setSuggestionInStorage({ type: 'suggestion', value: suggestion })
    gotoPage(suggestion)
  }
  const matches = useMediaQuery('@media (min-width:768px)')

  useEffect(() => {
    setSlidesPerView(!matches ? 1.3 : 3)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matches])
  return recentViewedHouse?.length > 0 ? (
    <>
      <style jsx global>{`
        .recent-viewed {
          --swiper-theme-color: #fff;
          --swiper-navigation-color: #050a22;
          position: relative;
          --swiper-navigation-size: 0;
        }
        .recent-viewed-center > .swiper-wrapper {
          justify-content: center;
        }
        .recent-viewed .swiper-button-disabled {
          display: none;
        }
        .recent-viewed > .swiper-button-next,
        .recent-viewed > .swiper-button-prev {
          position: absolute;
          top: 0;
          width: 128px;
          height: 100%;
          pointer-events: none;
        }
        .recent-viewed > .swiper-button-prev {
          left: -2px;
          background: linear-gradient(270deg, rgba(247, 247, 247, 0) 0%, #fff 47.4%);
        }
        .recent-viewed > .swiper-button-next {
          right: -2px;
          background: linear-gradient(90deg, rgba(247, 247, 247, 0) 0%, #fff 47.4%);
        }

        .recent-viewed > .swiper-button-prev::before {
          left: 3px;
        }
        .recent-viewed > .swiper-button-next::before {
          right: 3px;
        }

        .recent-viewed > .swiper-button-prev::after {
          left: 3px;
          transform: translateX(20px);
        }
        .recent-viewed > .swiper-button-next::after {
          right: 3px;
          transform: translateX(-20px);
        }

        .recent-viewed > .swiper-button-next::before,
        .recent-viewed > .swiper-button-prev::before {
          background: #fff;
          content: '';
          display: inline-block;
          width: 48px;
          height: 48px;
          border-radius: 50%;
          border: 1px solid #b0b0b0;
          position: absolute;
          box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.07);
          pointer-events: all;
        }

        .recent-viewed > .swiper-button-next::after,
        .recent-viewed > .swiper-button-prev::after {
          font-weight: 700;
          color: #717171;
          font-size: 14px;
          position: absolute;
          pointer-events: all;
        }

        .recent-viewed > .swiper-button-next:hover::before,
        .recent-viewed > .swiper-button-prev:hover::before {
          background: #e9e9e9;
        }

        .recent-viewed > .swiper-button-next:hover::after,
        .recent-viewed > .swiper-button-prev:hover::after {
          color: #001229;
        }
      `}</style>
      <Box className="max-w-[1160px] mx-auto bg-[transparent] text-primary w-full pb-8 pt-[20px] flex flex-col items-center justify-center">
        <Box className="text-2xl md:text-[28px] md:leading-[42px] font-semibold mb-5 w-full text-left md:text-center px-5">
          {intl.formatMessage({ id: 'home.input.search.recent.viewed.title' })}
        </Box>
        <Swiper
          autoHeight
          freeMode
          navigation={matches}
          modules={[Navigation, FreeMode]}
          className={`recent-viewed w-full ${matches ? 'recent-viewed-center' : ''}`}
          slidesPerView={slidesPerView}
        >
          {recentViewedHouse.slice(0, 3)?.map((item, index) => (
            <SwiperSlide
              key={[index, '-'].join('-')}
              className="flex flex-col items-start px-[10px] box-border md:max-w-[350px]"
            >
              <HouseCard
                allowTouchPicMove={false}
                className="w-full box-border shadow-sm"
                property={item}
                preLoadImg={false}
                handleClick={() => onClick(item)}
                isMapMode={false}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        {/* <Button
          className="mt-6 bg-white hover:bg-[#00A972] font-averta text-[#00A972] hover:text-white px-[78px] py-3 rounded-[30px] normal-case font-bold text-base w-fit"
          sx={{
            border: '1px solid #00A972'
          }}
        >
          Show more
        </Button> */}
      </Box>
    </>
  ) : null
}

export default RecentViewed
