const CityOrAreaLinear = props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_13957_51774)">
      <path
        d="M17.8738 17.8751H2.12367C1.8916 17.8751 1.66904 17.7829 1.50494 17.6188C1.34085 17.4547 1.24866 17.2322 1.24866 17.0001V10.4262C1.24864 10.3011 1.27542 10.1776 1.3272 10.0637C1.37897 9.94994 1.45453 9.84855 1.54879 9.7664L4.7487 6.97599V2.99995C4.7487 2.76788 4.84089 2.54532 5.00498 2.38122C5.16908 2.21713 5.39164 2.12494 5.62371 2.12494H17.8738C18.1059 2.12494 18.3285 2.21713 18.4926 2.38122C18.6567 2.54532 18.7489 2.76788 18.7489 2.99995V17.0001C18.7489 17.2322 18.6567 17.4547 18.4926 17.6188C18.3285 17.7829 18.1059 17.8751 17.8738 17.8751ZM7.37373 16.1251H9.99876V10.8243L6.49872 7.77225L2.99868 10.8243V16.1251H5.62371V12.6251H7.37373V16.1251ZM11.7488 16.1251H16.9988V3.87496H6.49872V5.73611C6.70347 5.73611 6.9091 5.80786 7.0736 5.95223L11.4487 9.7664C11.5429 9.84855 11.6185 9.94994 11.6702 10.0637C11.722 10.1776 11.7488 10.3011 11.7488 10.4262V16.1251ZM13.4988 9.12502H15.2488V10.875H13.4988V9.12502ZM13.4988 12.6251H15.2488V14.3751H13.4988V12.6251ZM13.4988 5.62498H15.2488V7.375H13.4988V5.62498ZM9.99876 5.62498H11.7488V7.375H9.99876V5.62498Z"
        fill="url(#paint0_linear_13957_51774)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_13957_51774"
        x1="3.43746"
        y1="2.12494"
        x2="11.5937"
        y2="18.6022"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#8C8C8C" />
      </linearGradient>
      <clipPath id="clip0_13957_51774">
        <rect width="20" height="20" fill="white" transform="translate(-0.00146484)" />
      </clipPath>
    </defs>
  </svg>
)
export default CityOrAreaLinear
