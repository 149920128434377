import { gql } from '@apollo/client'

export const searchSuggestionFragment = gql`
  fragment searchSuggestion on RentyAISuggestion {
    content
    ext {
      area
      city
      address
      propertyID
      propertyName
      filter {
        amenities
        areaEqualTo
        bedsFilterBy
        cityEqualTo
        maxPrice
        minPrice
        moveInEndTimeEqualTo
        moveInStartTimeEqualTo
        zipCode
        isLuxuryApartments
      }
      state
      zipCode
    }
    highlight
    id
    messageContent
    suggestionType
  }
`
