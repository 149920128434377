import { Box, useMediaQuery } from '@mui/material'

import Image from 'next/image'
import LazyImg from '@/components/img/lazyImg'
import { memo, useEffect, useState } from 'react'
import MessageItem from './messageItem'

const { LeftItem, RightVoiceItem } = MessageItem
const bgImg = `${process.env.CDN_PREFIX}/web/e6b2ed8c7098ac19.png`
const mobileBgImg = `${process.env.CDN_PREFIX}/web/b6ca32b58cfedeae.png`
const PropertyList = ({ isPC }) => {
  const PropertyListData = [
    {
      url: `${process.env.CDN_PREFIX}/web/95c48b102880735a.png`,
      name: 'Property 301'
    },
    {
      url: `${process.env.CDN_PREFIX}/web/e061527c8768872d.png`,
      name: 'Property 302'
    },
    {
      url: `${process.env.CDN_PREFIX}/web/825e51a28a8c5962.png`,
      name: 'Property 303'
    },
    {
      url: `${process.env.CDN_PREFIX}/web/746217a0d098b007.png`,
      name: 'Property 304'
    }
  ]
  return (
    <Box className="w-full max-w-[497px] flex justify-start mt-4">
      <Box
        className="text-black text-lg font-normal p-2 rounded-lg border ml-10 bg-[#F9FFFA] w-full flex gap-1"
        sx={{
          boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.05)',
          borderColor: 'rgba(211, 221, 204, 0.60)'
        }}
      >
        {(isPC ? PropertyListData : PropertyListData.slice(0, 2)).map(({ name, url }) => (
          <Box
            key={name.replace(/\s/g, '-')}
            className="flex flex-col w-full gap-1 p-[2.7px] pb-[5px] bg-white border-[0.5px] border-[#E9E9E9] rounded"
          >
            <Image
              className="w-[112px] h-[61px]"
              width={112}
              height={61}
              src={url}
              alt={name || 'renty img'}
            />
            <Box className="bg-[#F1F1F1] rounded-sm h-[10px] w-full" />
          </Box>
        ))}
      </Box>
    </Box>
  )
}

const VoiceInput = memo(() => {
  const [isPC, setIsPC] = useState(true)
  const matches = useMediaQuery('@media (min-width:768px)')
  useEffect(() => {
    setIsPC(matches)
  }, [matches])
  return (
    <Box className="h-[400px] md:h-[483px] box-border w-full flex overflow-hidden p-2 md:py-[10px] relative">
      <Box className="w-full max-w-[463px] shrink-0 md:ml-[10%] z-[2] flex flex-col justify-end md:justify-center p-4">
        <LeftItem
          className="hidden md:flex"
          content="Hi, I'm Alice. I'm here to assist you in discovering apartments.😊"
        />
        <RightVoiceItem content="00:13" />
        <LeftItem content="Nice! Here are the perfect properties matching your preference: 1bedrooms, all within your budget under 4000, Pet Friendly,Pool." />
        <PropertyList isPC={isPC} />
      </Box>
      <LazyImg
        url={isPC ? bgImg : mobileBgImg}
        fill
        useCurrentUrl
        className="w-full h-full md:!w-[879px] md:!h-[483px] p-2 absolute !inset-[unset] !top-0 !right-0 md:!right-10 rounded-2xl md:rounded-[40px] overflow-hidden md:scale-[1.2]"
      />
    </Box>
  )
})
VoiceInput.displayName = 'VoiceInput'
export default VoiceInput
