import { useLazyQuery, useMutation } from '@apollo/client'
import { useState } from 'react'
import searchSuggestionsGQL from '@/graphqls/searchSuggestions'
import searchRecordGQL from '@/graphqls/searchRecord'
import { RentyAiChatRequest } from '@/interface/message'

export const CHAT_INPUT_PLACEHOLDER = 'Search by property name, location, floor plan, or price'

export const enum SUGGESTION_TYPE_ENUM {
  GEO_LOCATION = 'GEO_LOCATION',
  PREFERENCE = 'PREFERENCE',
  PROPERTY_NAME = 'PROPERTY_NAME',
  ADDRESS = 'ADDRESS',
  ZIP_CODE = 'ZIPCODE',
  AREA_NAME = 'AREA_NAME',
  CITY_NAME = 'CITY_NAME'
}

export interface SuggestionsDataProps {
  id?: string | number
  suggestionType: SUGGESTION_TYPE_ENUM
  highlight: string
  content?: string
  ext?: {
    address?: string
    area?: string
    city?: string
    filter?: RentyAiChatRequest['historyFilter']
    propertyID?: string | number
    propertyName?: string
    state?: string
    zipCode?: string
  }
}

export type HOME_SEARCH_SEND_TYPE =
  | { type: 'suggestion'; value: SuggestionsDataProps }
  | { type: 'text'; value: string }

export const enum RECORD_SEARCH_TYPE_ENUM {
  DEFAULT = 'DEFAULT',
  SUGGESTION = 'SUGGESTIONS'
}
// 搜索关键字
export const useHomeSearch = () => {
  const [suggestions, setSuggestions] = useState<SuggestionsDataProps[]>([])
  const [getSearchSuggestions, { loading: searchLoading }] = useLazyQuery(searchSuggestionsGQL, {
    fetchPolicy: 'cache-and-network',
    onError: () => {
      // TODO
    },
    onCompleted(data) {
      const { searchSuggestions } = data ?? { searchSuggestions: [] }
      const tempSuggestions = Array.isArray(searchSuggestions) ? searchSuggestions : []
      setSuggestions(tempSuggestions as SuggestionsDataProps[])
    }
  })
  return {
    suggestions,
    setSuggestions,
    searchLoading,
    getSearchSuggestions
  }
}

// 上报输入历史
export const useRecordSearch = () => {
  const [recordSearch] = useMutation(searchRecordGQL, {
    onError: () => {
      // TODO
    }
  })
  return { recordSearch }
}
