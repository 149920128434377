import { SvgIcon } from '@mui/material'

const Dollar = props => (
  <SvgIcon {...props} width="43" height="42" viewBox="0 0 43 42" fill="none">
    <ellipse cx="25.599" cy="21.8899" rx="13.9011" ry="13.9011" fill="#CEF5E1" />
    <path
      d="M19.5 34C16.7848 34 14.2879 33.3818 12.0091 32.1455C9.7303 30.9091 7.86364 29.2606 6.40909 27.2V31.0909H3.5V22.3636H12.2273V25.2727H8.62727C9.79091 27.0182 11.3242 28.4242 13.2273 29.4909C15.1303 30.5576 17.2212 31.0909 19.5 31.0909C21.3182 31.0909 23.0212 30.7455 24.6091 30.0545C26.197 29.3636 27.5788 28.4303 28.7545 27.2545C29.9303 26.0788 30.8636 24.697 31.5545 23.1091C32.2455 21.5212 32.5909 19.8182 32.5909 18H35.5C35.5 20.2061 35.0818 22.2788 34.2455 24.2182C33.4091 26.1576 32.2636 27.8545 30.8091 29.3091C29.3545 30.7636 27.6576 31.9091 25.7182 32.7455C23.7788 33.5818 21.7061 34 19.5 34ZM3.5 18C3.5 15.7939 3.91818 13.7212 4.75455 11.7818C5.59091 9.84242 6.73636 8.14545 8.19091 6.69091C9.64545 5.23636 11.3424 4.09091 13.2818 3.25455C15.2212 2.41818 17.2939 2 19.5 2C22.2152 2 24.7121 2.61818 26.9909 3.85455C29.2697 5.09091 31.1364 6.73939 32.5909 8.8V4.90909H35.5V13.6364H26.7727V10.7273H30.3727C29.2091 8.98182 27.6758 7.57576 25.7727 6.50909C23.8697 5.44242 21.7788 4.90909 19.5 4.90909C17.6818 4.90909 15.9788 5.25455 14.3909 5.94545C12.803 6.63636 11.4212 7.5697 10.2455 8.74546C9.0697 9.92121 8.13636 11.303 7.44545 12.8909C6.75455 14.4788 6.40909 16.1818 6.40909 18H3.5Z"
      fill="#1C1B1F"
    />
    <path
      d="M18.1139 26.0958V27.9493H20.6089V26.1671C21.203 26.0483 21.7614 25.876 22.2842 25.6503C22.807 25.4246 23.2644 25.1275 23.6565 24.7592C24.0486 24.3909 24.3634 23.9453 24.601 23.4226C24.8387 22.8998 24.9575 22.2939 24.9575 21.6047C24.9575 20.6067 24.6723 19.6919 24.102 18.8602C23.5317 18.0285 22.3911 17.3037 20.6802 16.6859C19.2545 16.2107 18.2683 15.7948 17.7218 15.4384C17.1752 15.0819 16.902 14.5948 16.902 13.977C16.902 13.3592 17.1218 12.872 17.5614 12.5156C18.001 12.1591 18.6366 11.9809 19.4683 11.9809C20.2287 11.9809 20.8228 12.1651 21.2505 12.5334C21.6782 12.9017 21.9872 13.3592 22.1773 13.9057L24.4585 12.979C24.1971 12.1473 23.7159 11.4225 23.0149 10.8047C22.3139 10.1869 21.5357 9.8423 20.6802 9.77102V7.98883H18.1852V9.77102C17.0208 10.0086 16.1 10.5255 15.4228 11.3215C14.7455 12.1176 14.4069 13.0027 14.4069 13.977C14.4069 15.0938 14.7336 15.9968 15.3871 16.6859C16.0406 17.375 17.0683 17.9691 18.4703 18.4681C19.9673 19.0146 21.007 19.5018 21.5891 19.9295C22.1713 20.3572 22.4624 20.9156 22.4624 21.6047C22.4624 22.3889 22.1832 22.9651 21.6248 23.3335C21.0664 23.7018 20.3951 23.8859 19.6109 23.8859C18.8267 23.8859 18.1317 23.6424 17.5257 23.1552C16.9198 22.6681 16.4743 21.9374 16.1891 20.9632L13.8366 21.8899C14.1693 23.0305 14.6861 23.9513 15.3871 24.6523C16.0881 25.3533 16.997 25.8345 18.1139 26.0958Z"
      fill="#00A972"
    />
  </SvgIcon>
)
export default Dollar
