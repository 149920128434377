import { Box, Tab, Tabs, useMediaQuery } from '@mui/material'
import { statsigLog } from '@/lib/statsigAndGa'
import { useIntl } from 'react-intl'
import { useEffect, useState } from 'react'
import Languages from '../../icons/newHome/languages'
import Voice from '../../icons/newHome/voice'
import AIChat from '../../icons/newHome/aiChat'
import IntelligentAIChat from './intelligentAIChat'
import MultipleLanguages from './multipleLanguages'
import VoiceInput from './voiceInput'

const TabsList = () => {
  const [tabValue, setTabValue] = useState('0')
  const intl = useIntl()
  const [isPC, setIsPC] = useState(true)
  const matches = useMediaQuery('@media (min-width:768px)')
  useEffect(() => {
    setIsPC(matches)
  }, [matches])
  const TabListData = [
    {
      label: intl.formatMessage({ id: 'home.multiple.services.tab1.name' }),
      value: '0',
      icon: <Languages color={tabValue === '0' ? '#226643' : ''} className="text-[32px] mr-4" />,
      panel: <MultipleLanguages />
    },
    {
      label: intl.formatMessage({ id: 'home.multiple.services.tab2.name' }),
      value: '1',
      icon: <Voice color={tabValue === '1' ? '#226643' : ''} className="text-[32px] mr-4" />,
      panel: <VoiceInput />
    },
    {
      label: intl.formatMessage({ id: 'home.multiple.services.tab3.name' }),
      value: '2',
      icon: <AIChat color={tabValue === '2' ? '#226643' : ''} className="text-[32px] mr-4" />,
      panel: <IntelligentAIChat />
    }
  ]
  return (
    <>
      <Tabs
        value={tabValue}
        variant="scrollable"
        scrollButtons="auto"
        onChange={(_e, newValue) => {
          statsigLog('homepage_feature_introduce_click', {
            tabType: TabListData.filter(({ value: valueStr }) => valueStr === newValue)?.[0].label
          })
          setTabValue(newValue)
        }}
        className="flex mb-10"
        sx={{
          '& .MuiTabs-flexContainer': {
            gap: isPC ? '100px' : '16px',
            justifyContent: isPC ? 'center' : ''
          },
          '& .MuiTabs-indicator': {
            backgroundColor: '#226643'
          },
          '& .MuiButtonBase-root': {
            padding: 0,
            height: '48px'
          }
        }}
      >
        {TabListData.map(({ value, label, icon }) => (
          <Tab
            value={value}
            key={label.replace(/\s/g, '-')}
            label={
              <Box
                className={`${
                  tabValue === value ? 'text-[#226643]' : 'text-[#717171]'
                } text-lg md:text-[20px] leading-8 font-bold flex justify-center items-center`}
                sx={{
                  textTransform: 'none'
                }}
              >
                {isPC ? icon : null}
                {label}
              </Box>
            }
          />
        ))}
      </Tabs>
      <Box className="relative h-[400px] md:h-[483px]">
        {TabListData.map(({ value, label, panel }) => (
          <Box
            key={label.replace(/\s/g, '-')}
            sx={{
              padding: '0px !important',
              boxShadow: '0px 10px 40px 0px rgba(0, 0, 0, 0.05)',
              zIndex: tabValue === value ? '10' : '-100',
              pointerEvents: 'none'
            }}
            className="h-full md:p-10 rounded-xl md:rounded-[40px] box-border w-full bg-white overflow-hidden absolute top-0 left-0"
          >
            {panel}
          </Box>
        ))}
      </Box>
    </>
  )
}
export default TabsList
