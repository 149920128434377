import { SvgIcon } from '@mui/material'

const Voice = props => (
  <SvgIcon {...props} width="32" height="32" viewBox="0 0 32 32" fill="none">
    <rect x="11" y="1" width="10" height="19" rx="4.98701" fill={props?.color || '#717171'} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.16699 13.3335C8.16699 12.5051 7.49542 11.8335 6.66699 11.8335C5.83857 11.8335 5.16699 12.5051 5.16699 13.3335V14.6668C5.16699 20.1408 9.22698 24.6666 14.5 25.3971V29.8096C14.5 30.638 15.1716 31.3096 16 31.3096C16.8284 31.3096 17.5 30.638 17.5 29.8096V25.3972C22.7733 24.667 26.8337 20.1411 26.8337 14.6668V13.3335C26.8337 12.5051 26.1621 11.8335 25.3337 11.8335C24.5052 11.8335 23.8337 12.5051 23.8337 13.3335V14.6668C23.8337 18.9931 20.3266 22.5002 16.0003 22.5002C11.6741 22.5002 8.16699 18.9931 8.16699 14.6668V13.3335Z"
      fill={props?.color || '#717171'}
    />
  </SvgIcon>
)
export default Voice
