import { Box, Typography } from '@mui/material'
import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper'
import { useIntl } from 'react-intl'
import { CHAT_INPUT_PLACEHOLDER, SuggestionsDataProps } from './input'

export interface InputRecommendShowRef {
  isSuggestionMode: boolean
  getSuggestionIndex: () => number
}

interface InputRecommendShowProps {
  isMobileStyle: boolean
  show: boolean
  recommend: { latestSearch: string; suggestions: SuggestionsDataProps[] }
}
const InputRecommendShow = forwardRef<InputRecommendShowRef, InputRecommendShowProps>(
  (props, ref) => {
    const intl = useIntl()
    const { recommend, show, isMobileStyle } = props
    const swiperIndexRef = useRef<number>(0)
    // 如果recommend的suggestions不为空就是推荐模式 不然就是last search模式
    // 但是如果suggestions也有 last search也有 显示内容要用加上recent search
    const isSuggestionMode = useMemo(
      () => Array.isArray(recommend.suggestions) && recommend.suggestions.length > 0,
      [recommend]
    )

    useImperativeHandle(ref, () => ({
      isSuggestionMode,
      getSuggestionIndex: () => swiperIndexRef.current
    }))
    if (!show) return null
    return (
      <Box
        component="div"
        className="blog absolute left-0 h-9 top-1/2 translate-y-[-50%] overflow-hidden w-full"
      >
        {isSuggestionMode ? (
          <Swiper
            className="blog absolute left-0 h-9 top-1/2 translate-y-[-50%] overflow-hidden w-full"
            modules={[Autoplay]}
            loop
            centeredSlides
            autoplay={{
              delay: 3000,
              disableOnInteraction: false
            }}
            onAutoplay={swiper => {
              swiperIndexRef.current = swiper.realIndex
            }}
            direction="vertical"
          >
            {recommend.suggestions.map(item => (
              <SwiperSlide key={item.content}>
                <Typography
                  className={`font-averta leading-9 whitespace-nowrap text-ellipsis overflow-hidden ${
                    isMobileStyle ? 'text-sm text-secondary' : 'text-white text-base'
                  }`}
                >
                  {recommend.latestSearch === item.content
                    ? `${item.content} (${intl.formatMessage({
                        id: 'home.input.search.recent.search'
                      })})`
                    : item.content}
                </Typography>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <Typography
            className={`font-averta leading-9 whitespace-nowrap text-ellipsis overflow-hidden ${
              isMobileStyle ? 'text-sm text-secondary' : 'text-white text-base'
            }`}
          >
            {recommend.latestSearch
              ? `${recommend.latestSearch} (${intl.formatMessage({
                  id: 'home.input.search.recent.search'
                })})`
              : CHAT_INPUT_PLACEHOLDER}
          </Typography>
        )}
      </Box>
    )
  }
)

InputRecommendShow.displayName = 'InputRecommendShow'
export default InputRecommendShow
