const LocationLinear = props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.77594 16.7129L8.42882 11.8657C8.38127 11.6946 8.2557 11.5559 8.0901 11.4918L3.08158 9.55098C2.49904 9.32524 2.48156 8.50737 3.05393 8.25696L16.7624 2.25951C17.3581 1.9989 17.9563 2.62043 17.6731 3.20572L11.0805 16.8304C10.7994 17.4113 9.94875 17.3347 9.77594 16.7129Z"
      fill="#D9D9D9"
    />
    <path
      d="M9.77594 16.7129L8.42882 11.8657C8.38127 11.6946 8.2557 11.5559 8.0901 11.4918L3.08158 9.55098C2.49904 9.32524 2.48156 8.50737 3.05393 8.25696L16.7624 2.25951C17.3581 1.9989 17.9563 2.62043 17.6731 3.20572L11.0805 16.8304C10.7994 17.4113 9.94875 17.3347 9.77594 16.7129Z"
      fill="url(#paint0_linear_14869_157621)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_14869_157621"
        x1="9.99841"
        y1="1.5"
        x2="9.99841"
        y2="18.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#8B8B8B" />
      </linearGradient>
    </defs>
  </svg>
)
export default LocationLinear
