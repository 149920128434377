const RecentLinear = props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.99016 1.66669C5.39016 1.66669 1.66516 5.40002 1.66516 10C1.66516 14.6 5.39016 18.3334 9.99016 18.3334C14.5985 18.3334 18.3318 14.6 18.3318 10C18.3318 5.40002 14.5985 1.66669 9.99016 1.66669ZM9.99849 16.6667C6.31516 16.6667 3.33183 13.6834 3.33183 10C3.33183 6.31669 6.31516 3.33335 9.99849 3.33335C13.6818 3.33335 16.6652 6.31669 16.6652 10C16.6652 13.6834 13.6818 16.6667 9.99849 16.6667ZM9.81516 5.83335H9.76516C9.43183 5.83335 9.16516 6.10002 9.16516 6.43335V10.3667C9.16516 10.6584 9.31516 10.9334 9.57349 11.0834L13.0318 13.1584C13.3152 13.325 13.6818 13.2417 13.8485 12.9584C14.0235 12.675 13.9318 12.3 13.6402 12.1334L10.4152 10.2167V6.43335C10.4152 6.10002 10.1485 5.83335 9.81516 5.83335Z"
      fill="url(#paint0_linear_13957_51778)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_13957_51778"
        x1="3.74972"
        y1="1.66669"
        x2="12.9164"
        y2="18.3334"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#8C8C8C" />
      </linearGradient>
    </defs>
  </svg>
)
export default RecentLinear
