import { Box } from '@mui/material'
import dynamic from 'next/dynamic'
import useScrollOutMonitor from '@/hooks/useScrollOutMonitor'
import { useRouter } from 'next/router'
import useFeedbackTimeMonitor from '@/hooks/useFeedbackTimeMonitor'
import Header from '../header'
import InputSearch from './inputSearch'
import MultipleServices from './multipleServices'
import Faq from './faq'
import UserTrust from './userTrust'
import PopularCity from './popularCity'
import NearSearches from './nearSearches'
import FooterContent from './footerContent'
import GuessULike from './guessULike'
import HomeSeo from '../seo/homeSeo'
import CustomAlert from './customAlert'
import BrandDesc from './brandDesc'
import Feedback from '../feedback'

const ShareModal = dynamic(() => import('@/components/shareModel'), {
  ssr: false
})

const HomePage = ({
  isMobileDevice,
  recentViewedHouse,
  searchRecommend,
  searchRecent,
  searchPopular,
  userIpCity,
  guessULikeList
}) => {
  useFeedbackTimeMonitor()
  const { locale = 'en' } = useRouter()
  const { containerRef, targetRef, arrived } = useScrollOutMonitor()
  return (
    <Box
      id="home-page-container"
      className="relative overflow-x-hidden overflow-auto h-[var(--doc-h)]"
      ref={containerRef}
    >
      <HomeSeo properties={[]} locale={locale} />
      <Header isShowMap={false} isMobileDevice={isMobileDevice} showLogo transparent={!arrived} />
      <InputSearch
        isPC={!isMobileDevice}
        recentViewedHouse={recentViewedHouse}
        searchRecommend={searchRecommend}
        searchRecent={searchRecent}
        searchPopular={searchPopular}
        scrollFlagRef={targetRef}
      />
      <MultipleServices />
      <Faq isPC={!isMobileDevice} />
      <UserTrust />
      <GuessULike guessULikeList={guessULikeList} />
      <PopularCity isPC={!isMobileDevice} />
      <BrandDesc />
      <NearSearches city={userIpCity} />
      <FooterContent isPC={!isMobileDevice} />
      <ShareModal />
      <CustomAlert />
      <Feedback />
    </Box>
  )
}

export default HomePage
